import { Pane } from 'evergreen-ui';
import React, { useState, useEffect } from 'react';

function AuctionUpdateTime({ updateTime }: { updateTime: string }) {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const updateAt = new Date();

      // Set the time zone to GMT+4
      now.setHours(now.getHours() + 4);

      // Set the update time
      const [hours, minutes, seconds] = updateTime.split(':');
      updateAt.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));
      updateAt.setHours(updateAt.getHours() + 4); // Adjust to GMT+4

      // Calculate the difference in milliseconds
      let difference = updateAt.getTime() - now.getTime();

      if (difference < 0) {
        // If the update time has already passed, add one day to the difference
        difference += 24 * 60 * 60 * 1000;
      }

      // Calculate remaining minutes and seconds
      const minutesLeft = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const secondsLeft = Math.floor((difference % (1000 * 60)) / 1000);

      // Format the remaining time
      if (difference > 0) {
        const formattedTimeLeft = `${minutesLeft}m ${secondsLeft}s`;
        setTimeLeft(formattedTimeLeft);
      } else {
        setTimeLeft('+1 hour');
      }
    };

    // Calculate initially and set up an interval to update every second
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [updateTime]);

  return (
    <Pane className="flex items-center gap-2">
      {timeLeft} <span className="animate-pulse !duration-2000 !delay-2000 !rounded-full !w-2 !h-2 !bg-yellow-600/80"></span>
    </Pane>
  );
}

export default AuctionUpdateTime;
