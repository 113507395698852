// Copart Vehicle Reducer
import { createSlice } from "@reduxjs/toolkit";
import { toaster } from "evergreen-ui";

const userSlice = createSlice({
	name: 'user',
	initialState: {
		loading: false,
		list: [],
		loadingUpdated: false,
		updateUser: null,
		createUser: false,
		createUserMessage: null,
		groups: {
			list: [],
			loading: false
		},
		error: {
			status: false,
			message: null,
			errors: null
		}
	},
	reducers: {
		userGroups: (state, action) => {
			switch(action.payload.type) {
				case 'loading':
					state.groups.loading = action.payload.data
				break;
				case 'set':
					state.groups.loading = false
					state.groups.list = action.payload.data
				break;
				default:
					toaster.danger('Something went wrong')
			}
		},
		setLoading: (state, action) => {
			state.loading = action.payload
			state.error.status = false;
		},
		setUsers: (state, action) => {
			state.loading = false
			state.list = action.payload
			state.error = {
				status: false,
				message: null,
				errors: null
			}
		},
		setUpdateUser: (state, action) => {
			state.loadingUpdated = false
			state.updateUser = action.payload
			state.error = {
				status: false,
				message: null,
				errors: null
			}
			state.createUser = false
		},
		createUserState: (state) => {
			state.createUser = true
			state.error = {
				status: false,
				message: null,
				errors: null
			}
			state.updateUser = null
		},
		setError: (state, action) => {
			state.error = action.payload
			state.error.status = true
		},
		setCreateUserMessage: (state, action) => {
			state.createUserMessage = action.payload
		}
	}
})

export const { setUsers, setLoading, setUpdateUser, setError, createUserState, setCreateUserMessage, userGroups } = userSlice.actions
export default userSlice.reducer