import { LocalStorage } from "../helpers/LocalStorage.helper";
import { request } from "../services/axios.config";

// Mock API functions for login and logout
const api = {
  login: async (username: string, password: string) => {

    try {
      const response = await request.post('/api/v1/auth/login', { username, password });

      LocalStorage.set('token', response.data.data.token);

      return { success: true, data: response.data.data };
    } catch (error: any) {
      return { success: false, data: error.response.data};
    }

  },
  validateToken: async () => {

    try {
      const response = await request.get('/api/v1/auth/authorized?details=true', {
        headers: {
          Authorization: LocalStorage.get('token')
        }
      });

      return { success: true, data: response.data.user };
    }
    catch (error: any) {
      console.log(error)
      if (error.response.status === 401) {
        LocalStorage.remove('token');
        window.location.href = '/auth';
      }

      return { success: false, data: error.response.data };
    }
  },
  logout: async () => {

    LocalStorage.remove('token');
    
    return { success: true };
  },
};

export default api;