import AuthView from 'components/features/auth';
import { loginRequest } from '../../redux/actions/auth.action';
import { Error, IsAuthenticated } from '../../redux/selectors/authSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Pane } from 'evergreen-ui';

const AuthContainer: React.FC = () => {
  const isAuthenticated = useSelector(IsAuthenticated);
	const error = useSelector(Error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/in');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = (username: string, password: string) => {
    loginRequest(username, password)(dispatch, navigate);
  };

  if(!isAuthenticated) {
    return <AuthView isAuthenticated={isAuthenticated} error={error} onLogin={handleLogin} />;
  }else{
    return (
      <Pane>

      </Pane>
    )
  }
};

export default AuthContainer;