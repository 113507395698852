import { Button, Pagination, Pane, Table, TextInput } from 'evergreen-ui'
import { useContext, useEffect,  useState } from 'react'
import './index.css';
import {  FwPill } from '@freshworks/crayons/react';
import { createGroup, getUserGroups, getUsers, saveEditedGroup } from '../../../redux/actions/user.action';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/ui/Loading';
import { useNavigate } from 'react-router-dom';
import { GrUserManager, GrUserWorker } from "react-icons/gr";
import { MdAdminPanelSettings, MdGroupWork } from 'react-icons/md';
import { GlobalContext } from 'providers/global.provider';


type Role =  {
	id: number
	title: string
}
type Group =  {
	id: number
	title: string
}
interface User {
	id: number
	name: string
	surname: string
	username: string
	email: string
	phone: string
	address: string
	avatar: string
	role: Role
	archived: boolean
	activated: boolean
	group: Group
}


const Users = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const userLoading = useSelector((state: any) => state.user.loading)
	const userList = useSelector((state: any) => state.user.list)
	const userGroups = useSelector((state: any) => state.user.groups)
	const {role} = useContext<any>(GlobalContext)

	const [editedGroup, setEditedGroup] = useState<any>(null);
	const [newGroup, setNewGroup] = useState<any | boolean>(null);

	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [filter, setFilter] = useState<string>("");
	// const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

	// Pagination
	const [currentPage, setCurrentPage] = useState<number>(1);
	const itemsPerPage: number = 10; 
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const filteredUsers = userList.filter(
		(user: User) => 
				user.name.toLowerCase().includes(filter.toLowerCase()) ||
				user.surname.toLowerCase().includes(filter.toLowerCase()) ||
				(user.phone && user.phone.includes(filter) ) ||
				user.username.toLowerCase().includes(filter.toLowerCase()) ||
				(user.email && user.email.toLowerCase().includes(filter.toLowerCase())) ||
				user.role.title.toLowerCase().includes(filter.toLowerCase())
		);

	const usersToDisplay = filteredUsers.slice(startIndex, endIndex);


	useEffect(() => {
		getUsers()(dispatch)
		getUserGroups()(dispatch)
	}, []) // eslint-disable-line

	const GetUserList = usersToDisplay.map((user: User) => (

		<Table.Row 
			key={user.id} 
			isSelectable={role.isAdmin() ? true : user.role.id === 1 ? false : true}
			isSelected={user.id === selectedUser?.id}
			onSelect={() => setSelectedUser(user)}
			className='[&[aria-current="true"]]:!bg-gray-300/30 [&[aria-current="true"]]:!shadow-inner [&[aria-current="true"]>div.action>button]:inline-block [&[aria-current="true"]>div>span]:!text-black '
		>
			<Table.TextCell><Pane>{user.name} {user.surname}</Pane><Pane className="text-xs opacity-60 italic">{user.username}</Pane></Table.TextCell>
			<Table.TextCell><Pane>{user.phone}</Pane><Pane className="text-xs opacity-60 italic">{user.address}</Pane></Table.TextCell>
			<Table.TextCell>
				<Pane className="text-xs opacity-60 italic flex gap-1">
					<FwPill color={user.role.id === 1 ? 'green' : user.role.id === 2 ? 'orange' : 'red'}>
						{user.role.id === 1 && <MdAdminPanelSettings className='mr-1' />}
						{user.role.id === 2 && <GrUserManager className='mr-1' />}
						{user.role.id === 3 && <GrUserWorker className='mr-1' />}
						{user.role.title}
					</FwPill>
					{user.group && <FwPill color="yellow"><MdGroupWork className='mr-1' /> {user.group.title}</FwPill> }
				</Pane>
			</Table.TextCell>
			<Table.TextCell>
				<Pane>
					{user?.archived ? (
								<FwPill color="orange">
										<Pane className="w-2 h-2 status-orange animate-pulse rounded-full flex items-center justify-center mr-2" />
										Suspended
								</FwPill>
						) : !user?.activated ? (
								<FwPill color="red">
										<Pane className="w-2 h-2 status-red animate-pulse rounded-full flex items-center justify-center mr-2" />
										Deleted
								</FwPill>
						) : (
								<FwPill color="green">
										<Pane className="w-2 h-2 status-green animate-pulse rounded-full flex items-center justify-center mr-2" />
										Active
								</FwPill>
						) }
					</Pane>
			</Table.TextCell>
			<Table.Cell className={`action flex flex-row gap-3`}>
					<Button className={`w-fit hidden`} onClick={() => navigate(`/in/users/${user.id}`)}>Edit</Button>
					<Button className={`w-fit hidden bg-red-500 text-white hover:!bg-red-600`}>Delete</Button>
			</Table.Cell>
		</Table.Row>

	))
	

	const GetGroupList = userGroups.list.map((group: any) => (
		
		<Table.Row 
			key={group.id} 
			className={`cursor-pointer ${(group.id === editedGroup?.id) && (group.name === editedGroup?.name && group.price === editedGroup?.price) ? 'bg-gray-500/10 shadow-inner' : (group.id === editedGroup?.id) && (group.name !== editedGroup?.name || group.price !== editedGroup?.price) ? 'bg-green-500/10 shadow-inner' : ''}`}
			onClick={() => group.id !== editedGroup?.id && setEditedGroup(group)}
		>
			<Table.TextCell className="group">
			{group.id === editedGroup?.id ? 
					<TextInput width={'w-24'}  value={editedGroup.name} onChange={(e: any) => setEditedGroup({...editedGroup, name: e.target.value})} /> :
					<span>{group.name}</span>
				}
			</Table.TextCell>
			<Table.TextCell className="group">
			{group.id === editedGroup?.id ? 
					<TextInput width={'w-24'} type={'number'} value={editedGroup.price} onChange={(e: any) => setEditedGroup({...editedGroup, price: e.target.value})} /> :
					<span>{'$'+group.price}</span>
				}
			</Table.TextCell>
			<Table.Cell className="">
				{group.id === editedGroup?.id && 
					<Button disabled={group.name === editedGroup.name && group.price === editedGroup.price} onClick={() => saveEditedGroup(editedGroup, group.name === editedGroup.name)(dispatch)}>Save</Button>
				}
			</Table.Cell>

		</Table.Row>

	))

	return (
		<Pane className="mt-8 flex flex-col gap-5">
      <Pane className="box bg-white  p-5 rounded-md shadow-sm">
        <Pane className="label">Users</Pane>
				<Table className='!text-xs'>
					<Table.Head className='bg-dark'>
						<Table.SearchHeaderCell onChange={(value) => setFilter(value)}/>
						<Table.TextHeaderCell>Address</Table.TextHeaderCell>
						<Table.TextHeaderCell>Role / Group</Table.TextHeaderCell>
						<Table.TextHeaderCell>Status</Table.TextHeaderCell>
						<Table.TextHeaderCell className="flex justify-end [&>div]:flex-none">
							<Button appearance='primary' className="w-fit self-end text-white border-0 hover:!border-0 hover:!bg-primary-600" onClick={() => navigate('/in/users/create')}>Create User</Button>
						</Table.TextHeaderCell>
					</Table.Head>
					<Table.Body>
						{userLoading ? <Table.Row><Table.TextCell><Loading /></Table.TextCell></Table.Row> : GetUserList}
					</Table.Body>
				</Table>
				<Pagination
						className="m-4 self-end [&>*]:gap-1"
						page={currentPage}
						onNextPage={() => setCurrentPage(currentPage + 1)}
						onPreviousPage={() => setCurrentPage(currentPage - 1)}
						totalPages={Math.ceil(filteredUsers.length / itemsPerPage)}
						onPageChange={(page) => setCurrentPage(page)}
				></Pagination>
			</Pane>

			<Pane className="box bg-white  p-5 rounded-md shadow-sm">
        <Pane className="label">User Groups</Pane>
				<Table className='!text-xs'>
					<Table.Head className=''>
						<Table.TextHeaderCell>Title</Table.TextHeaderCell>
						<Table.TextHeaderCell>Price</Table.TextHeaderCell>
						<Table.TextHeaderCell className="flex justify-end [&>div]:flex-none">
							<Button appearance='primary' className={`w-fit self-end ${newGroup ? '!bg-orange-500' : ''}  hover:!text-white border-0 hover:!border-0`} onClick={() => setNewGroup(!newGroup)}>Create Group</Button>
						</Table.TextHeaderCell>
					</Table.Head>
					<Table.Body>
						{userGroups.loading ? <Table.Row><Table.TextCell><Loading /></Table.TextCell></Table.Row> : GetGroupList}
						{newGroup && <Table.Row className="!bg-orange-500/10  shadow-inner">
							<Table.TextCell>
								<TextInput placeholder='Group Name' width={'w-24'}  value={newGroup.name} onChange={(e: any) => setNewGroup({...newGroup, name: e.target.value})} />
							</Table.TextCell>
							<Table.TextCell>
								<TextInput placeholder='Price' width={'w-24'} type={'number'} value={newGroup.price} onChange={(e: any) => setNewGroup({...newGroup, price: e.target.value})} />
							</Table.TextCell>
							<Table.Cell>
								<Button disabled={newGroup.name === '' || newGroup.price === '' || !newGroup.name || !newGroup.price} onClick={() => {
									createGroup(newGroup)(dispatch)
									setNewGroup(!newGroup)
									setEditedGroup({id: 0})
								}}>Save</Button>
							</Table.Cell>
							</Table.Row>}
					</Table.Body>
				</Table>
				<Pagination
						className="m-4 self-end [&>*]:gap-1"
						page={currentPage}
						onNextPage={() => setCurrentPage(currentPage + 1)}
						onPreviousPage={() => setCurrentPage(currentPage - 1)}
						totalPages={Math.ceil(filteredUsers.length / itemsPerPage)}
						onPageChange={(page) => setCurrentPage(page)}
				></Pagination>
			</Pane>
		</Pane>
	)
}


export default Users