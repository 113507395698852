// Copart Vehicle Reducer
import { createSlice } from "@reduxjs/toolkit";

const auctionVehiclesSlice = createSlice({
	name: 'auctionVehicles',
	initialState: {
		loading: false,
		list: [],
		pending: 0,
		won: 0,
		lost: 0,
		count: 0,
		error: {
			status: false,
			message: null
		}
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setAuctionVehicles: (state, action) => {
			state.loading = false
			state.list = action.payload
			state.count = action.payload[0] ? action.payload[0].total : 0

			if(action.payload.length > 0) {
				state.pending = action.payload[0].totalPending
				state.won = action.payload[0].totalWin
				state.lost = action.payload[0].totalLost
			}

		}
	}
})

export const { setAuctionVehicles, setLoading } = auctionVehiclesSlice.actions
export default auctionVehiclesSlice.reducer