import { Button, Label, Pane, SelectMenu, Spinner } from 'evergreen-ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuctions, getCalculatedPrice, getCalculatorText, getStates, getTypes, updateCalculatorText } from '../../../redux/actions/calculator.action'
import { AiFillCalculator } from 'react-icons/ai'

const Calculator = () => {

	const dispatch = useDispatch()
	const calculatorState = useSelector((state: any) => state.calculator)
	const authState = useSelector((state: any) => state.auth)
	const [editText, setEditText] = useState<boolean>(false)
	const [text, setText] = useState<string>('')
	const [filter, setFilter] = useState<any>("") // eslint-disable-line
	const [auction, setAuction] = useState<any>({})
	const [state, setState] = useState<any>({})
	const [type, setType] = useState<any>({})


	useEffect(() => {
		
		getCalculatorText()(dispatch)
		setText(calculatorState.message.text)

		getAuctions()(dispatch)
		getTypes()(dispatch)

	}, []) // eslint-disable-line

	const handleAuctionSelect = (item: any) => {
		setAuction(item)
		setState({})
		getStates(item.value)(dispatch)
	}

	return (
		<Pane className="mt-5 relative flex flex-col !gap-5" >
			<Pane className="bg-white p-5 rounded-md shadow-sm relative">
				<h1 className="text-xl text-red-600 flex gap-3 items-center"> <AiFillCalculator size={30} /> Calculator</h1>
				<Pane className={`${authState.user.role.id === 1 || authState.user.role.id === 2 ? '' : 'hidden'} absolute right-2 top-2 cursor-pointer rounded-md py-1 px-2 text-center text-sm hover:!bg-red-600 hover:!text-white hover:bg-transparent border border-transparent !border-red-600 !text-red-600`} onClick={() => {
					if(editText) {
						updateCalculatorText(text || calculatorState.message.text)(dispatch)
						setEditText(false)
					}else{
						setEditText(true)
						setText(calculatorState.message.text)
					}
					
				}}>{editText ? 'Done' : 'Change Message'}</Pane>
				<Pane className="mt-5 text-neutral-700 relative grid">

					{calculatorState.message.loading ? <Spinner className="self-center" /> : editText ? <textarea className="w-full h-40 border p-2 rounded-md border-red-300" value={text} onChange={(e) => setText(e.target.value)} /> : <div dangerouslySetInnerHTML={{ __html: calculatorState.message.text.replace(/\n/g, '<br />') }} />}
				</Pane>
			</Pane>
			<Pane className="bg-white p-5 rounded-md shadow-sm relative flex gap-5 max-md:flex-col">
				<Pane className='grid gap-5' style={{ gridTemplateColumns: '1fr 1fr' }}>
					<Pane className="flex flex-col gap-2 w-[200px]">
							<Label>Auction</Label>
							<SelectMenu
									title="Select Auction"
									onFilterChange={(filter) => setFilter(filter)}
									options={calculatorState.auctions.data.map((auction: any) => {
											return {
												label: auction.name,
												value: auction.id
											}
										}) }
									selected={auction?.value}
									onSelect={(item) => handleAuctionSelect(item)}
							>
									<Button>{calculatorState.auctions.loading ? 'Loading...' : auction?.label ||'Select Auction'}</Button>
							</SelectMenu>
					</Pane>
					<Pane className="flex flex-col gap-2 w-[300px] overflow-hidden">
							<Label>States</Label>
							<SelectMenu
									title="Select State"
									onFilterChange={(filter) => setFilter(filter)}
									options={auction?.value ? calculatorState.states.data.map((state: any) => {
											return {
												label: state.name,
												value: state.id
											}
									}) : [{label: 'Select Auction', value: '', disabled: true}] }
									selected={state?.value}
									onSelect={(item) => setState(item)}
							>
									<Button>{calculatorState.states.loading ? 'Loading...' : state?.label ||'Select State'}</Button>
							</SelectMenu>
					</Pane>
					<Pane className="flex flex-col gap-2 w-[200px]">
							<Label>Vehicle Type</Label>
							<SelectMenu
									title="Select Type"
									onFilterChange={(filter) => setFilter(filter)}
									options={calculatorState.types.data.map((type: any) => {
											return {
												label: type.name,
												value: type.id
											}
									}) }
									selected={type?.value}
									onSelect={(item) => setType(item)}
							>
									<Button>{calculatorState.states.loading ? 'Loading...' : type?.label ||'Select Type'}</Button>
							</SelectMenu>
					</Pane>
					<Pane className="flex flex-col gap-2 items-center justify-end">
						<Button onClick={() => getCalculatedPrice(auction?.value, state?.value, type?.value)(dispatch)} className="w-full !bg-red-600 hover:!bg-transparent !text-white border-0 hover:!text-red-600 hover:!border-red-600">Calculate</Button>
					</Pane>
				</Pane>
				<Pane className="bg-red-600/20 w-full rounded-md text-neutral-800 p-5 flex gap-3 items-center text-center justify-center">
					{calculatorState.price.loading ? <Spinner className="!stroke-red-600" /> : 
						calculatorState.price.data === '' ? 
							<h1 className="text-lg text-red-600 ">Calculate to see the results</h1> : 
							<h1 className="text-3xl font-bold">{calculatorState.price.data} USD</h1>}
				</Pane>
			</Pane>
		</Pane>
	)
}

export default Calculator