import { Button, KnownVehicleIcon, Pagination, Pane, SearchInput, Table } from 'evergreen-ui'
import { useEffect,  useState } from 'react'
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/ui/Loading';
import { useNavigate } from 'react-router-dom';
import { getVehicles } from '../../../redux/actions/vehicles.action';

interface myVehicle {
	id: number
	vin: string
	brand: {name: string}
	model: {name: string}
	year: string
	lot_number: string
	key: number
	archived: boolean
	city: string
	dealer: {
		name: string
		surname: string
	}

}

const Vehicles = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const VehiclesLoading = useSelector((state: any) => state.vehicles.loading)
	const VehiclesList = useSelector((state: any) => state.vehicles.list)

	const [selectedVehicle, setSelectedVehicle] = useState<myVehicle | null>(null);
	const [filter, setFilter] = useState<string>("");
	// const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);


	// Pagination
	const [currentPage, setCurrentPage] = useState<number>(1);
	const itemsPerPage: number = 10; 
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const filteredVehicles = VehiclesList.filter(
		(vehicle: myVehicle) => 
			vehicle.vin?.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.lot_number?.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.brand.name?.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.model.name?.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.dealer.name?.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.dealer.surname?.toLowerCase().includes(filter.toLowerCase())
		);

	const vehiclesToDisplay = filteredVehicles.slice(startIndex, endIndex);

	useEffect(() => {
		getVehicles(currentPage)(dispatch)
	}, [currentPage]) // eslint-disable-line

	const GetVehiclesList = vehiclesToDisplay.map((vehicle: myVehicle) => (

		<Table.Row 
			key={vehicle.id} 
			isSelected={vehicle.id === selectedVehicle?.id}
			onSelect={() => setSelectedVehicle(vehicle)}
			onClick={() => navigate(`/in/vehicles/${vehicle.id}`)}
			isSelectable
			className='[&[aria-current="true"]]:!bg-gray-300/30 [&[aria-current="true"]]:!shadow-inner [&[aria-current="true"]>div.action>button]:inline-block [&[aria-current="true"]>div>span]:!text-black cursor-pointer'
		>
			<Table.Cell className="">IMAGE</Table.Cell>
			<Table.Cell className="">{vehicle.year} {vehicle.brand.name} {vehicle.model.name}</Table.Cell>
			<Table.Cell className="">{vehicle.vin}</Table.Cell>
			<Table.Cell className="">{vehicle.lot_number}</Table.Cell>
			<Table.Cell className="">{vehicle.dealer.name} {vehicle.dealer.surname}</Table.Cell>
		</Table.Row>

	))

	return (
		<Pane className="mt-8 flex flex-col gap-5">

			<Pane className="box bg-white  p-5 rounded-md shadow-sm">
				<Pane className="flex gap-4">
					<Pane className="box">
						<Pane className="label">Tools</Pane>
						<SearchInput placeholder='Search by content' onChange={(e: any) => setFilter(e.target.value)} value={filter} />
					</Pane>
					<Pane className='flex justify-end self-end'>
						<Button
							className="!bg-red-600 self-end !text-white hover:!text-red-600 hover:!border-red-600 hover:!bg-transparent"
							onClick={() => navigate('/in/vehicles/create')}
							iconBefore={<KnownVehicleIcon />}
						>
							Add Vehicle
						</Button>
					</Pane>
				</Pane>
			</Pane>

      <Pane className="box bg-white  p-5 rounded-md shadow-sm">
        <Pane className="label">Vehicles</Pane>
				<Table className='!text-xs'>
					<Table.Head className='!pr-0'>
						{/* <Table.SearchHeaderCell onChange={(value) => setFilter(value)}/> */}
						<Table.TextHeaderCell>Image</Table.TextHeaderCell>
						<Table.TextHeaderCell>Name</Table.TextHeaderCell>
						<Table.TextHeaderCell>Vincode</Table.TextHeaderCell>
						<Table.TextHeaderCell>Lot Number</Table.TextHeaderCell>
						<Table.TextHeaderCell>Dealer</Table.TextHeaderCell>
					</Table.Head>
					<Table.Body>
						{VehiclesLoading ? <Table.Row><Table.TextCell><Loading /></Table.TextCell></Table.Row> : filteredVehicles.length > 0 ? GetVehiclesList : <Table.Row><Table.TextCell>No vehicles found</Table.TextCell></Table.Row>}
					</Table.Body>
				</Table>
				<Pagination
						className="m-4 self-end [&>*]:gap-1"
						page={currentPage}
						onNextPage={() => setCurrentPage(currentPage + 1)}
						onPreviousPage={() => setCurrentPage(currentPage - 1)}
						totalPages={Math.ceil(filteredVehicles.length / itemsPerPage)}
						onPageChange={(page) => setCurrentPage(page)}
				></Pagination>
			</Pane>
		</Pane>
	)
}


export default Vehicles