import { createContext } from "react";
import {  RoleValidation } from "../utils/helpers/RoleValidation";

// GlobalContext

export const GlobalContext = createContext<any>(null);

export const GlobalProvider = ({ children }: any) => {
    

    const role = RoleValidation();
    // const hasPermission = (path: string) => HasPermission(path)

    const values = {
        role,
        // hasPermission
    }

    

    return (
        <GlobalContext.Provider value={values}>
            {children}
        </GlobalContext.Provider>
    )

}