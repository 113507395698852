import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    create: {
        brands: {
            data: null,
            loading: false,
            message: ''
        },
        models: {
            data: null,
            loading: false,
            message: ''
        },
        dealers: {
            data: null,
            loading: false,
            message: ''
        },
        clients: {
            data: null,
            loading: false,
            message: ''
        },
        sendData: {
            vin: null,
            container: null,
            lot: null,
            year: null,
            city: null,
            hasKey: null,
            brand_id: null,
            model_id: null,
            dealer_id: null,
            client_id: null
        },
        data: {
            id: null,
            vin: null,
            container: null,
            lot: null,
            year: null,
            city: null,
            hasKey: null,
            brand_id: null,
            model_id: null,
            dealer_id: null,
            client_id: null
        },
        message: '',
        loading: false,
        error: false
    },
    images: {
        data: null,
        loading: false,
        message: '',
        error: false
    },
    fillFromBid: {
        data: null,
        loading: false,
        message: '',
        error: false
    }
}

const allDataSlice = createSlice({
    name: 'allData',
    initialState: initialState,
    reducers: {
        dealers: (state, action) => {
            switch (action.payload.type) {
                case 'loading':
                    state.create.dealers.loading = true;
                    state.create.dealers.message = "Loading Dealers";
                break;
                case 'success':
                    state.create.dealers.loading = false;
                    state.create.dealers.data = action.payload.data;
                    state.create.dealers.message = "";
                    break;
                case 'error':
                    state.create.dealers.loading = false;
                    state.create.dealers.message = action.payload.data.response.message
                break;
                default:
                    state.create.message = 'type is not correct'
            }
        },
        brands: (state, action) => {
            switch (action.payload.type) {
                case 'loading':
                    state.create.brands.loading = true;
                    state.create.brands.message = "Loading Brands";
                break;
                case 'success':
                    state.create.brands.loading = false;
                    state.create.brands.data = action.payload.data;
                    state.create.brands.message = "";
                    break;
                case 'error':
                    state.create.brands.loading = false;
                    state.create.brands.message = action.payload.data.response.message
                break;
                default:
                    state.create.message = 'type is not correct'
            }
        },
        models: (state, action) => {
            switch (action.payload.type) {
                case 'loading':
                    state.create.models.loading = true;
                    state.create.brands.message = "Loading Models";
                    break;
                case 'success':
                    state.create.models.loading = false;
                    state.create.models.data = action.payload.data;
                break;
                case 'error':
                    state.create.models.loading = false;
                    state.create.models.message = action.payload.data.response.message
                break;
                default:
                    state.create.message = 'type is not correct'
            }
        },
        create: (state, action) => {
            switch(action.payload.type){
                case 'loading':
                    state.create.loading = true;
                    state.create.sendData = action.payload.data
                    state.create.message = "Loading";
                    state.create.error = false
                break;
                case 'success':
                    state.create.loading = false;
                    state.create.message = "";
                    state.create.data = action.payload.data
                    state.create.error = false
                break;
                case 'error':
                    state.create.loading = false;
                    state.create.message = action.payload.data.response.data.message
                    state.create.error = action.payload.data.response.data.errors
                break;
                default:
                    state.create.message = 'type is not correct'
            }
        },
        setImages: (state, action) => {
            switch(action.payload.type){
                case 'loading':
                    state.images.loading = true;
                    state.images.message = "Loading";
                    state.images.data = null
                break;
                case 'success':
                    state.images.loading = false;
                    state.images.message = "";
                    state.images.data = action.payload.data
                break;
                case 'error':
                    state.images.loading = false;
                    state.images.message = action.payload.data.response
                    state.images.data = null
                break;
                default:
                    state.images.message = 'type is not correct'
            }
        },
    }
})

export const { brands, models, create, setImages, dealers} = allDataSlice.actions;
export default allDataSlice.reducer