import { setLoading, setMyVehicles, setError } from "../reducers/myVehicles.reducer"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"


export const getMyVehicles = () => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token) {

			dispatch(setLoading(true))

			request.get('/api/v1/dealers/cars', {
					headers: {
							Authorization: token
					}
			}).then(res => {
					dispatch(setMyVehicles(res.data.data))
			}).catch(err => {
					dispatch(setError(err))
			})
	}
}

