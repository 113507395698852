import { Pane } from 'evergreen-ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVehicleById } from '../../../redux/actions/vehicles.action';
import Loading from 'components/ui/Loading';

const View = () => {

	const	dispatch = useDispatch();
	const { id } = useParams();

	const vehicle = useSelector((state: any) => state.vehicles.view)
	const loading = useSelector((state: any) => state.vehicles.loading)

	const [image, setImage] = useState<string>("");
	const [allImage, setAllImage] = useState<string[]>([]);
	const [imagesCollected, setImagesCollected] = useState<boolean>(false); // eslint-disable-line


	useEffect(() => {
		getVehicleById(id)(dispatch)
		setImage("")
	}, [id]) // eslint-disable-line

	useEffect(() => {
		!loading && checkImage()
	}, [vehicle]) // eslint-disable-line
	

	const checkImage = () => {
		
		if (vehicle?.images?.auction) {
			!image && setImage(vehicle.images.auction[0].url)
			setAllImage(vehicle.images.auction)
		}

		if (vehicle?.images?.america) {
			!image && setImage(vehicle.images.america[0].url)
			setAllImage([...allImage, vehicle.images.america])

		}

		if (vehicle?.images?.loading) {
			!image && setImage(vehicle.images.loading[0].url)
			setAllImage([...allImage, vehicle.images.loading])
		}

		if (vehicle?.images?.georgia) {
			!image && setImage(vehicle.images.georgia[0].url)
			setAllImage([...allImage, vehicle.images.georgia])
		}

		allImage.length > 0 && setImagesCollected(true)

	}


	const getFirstImage = () : string | undefined => {

		if (vehicle.images?.auction && vehicle.images.auction.length > 0) {
			return vehicle.images.auction[0].url
		}
		if (vehicle.images?.america && vehicle.images.america.length > 0) {
			return vehicle.images.america[0].url
		}
		if (vehicle.images?.loading && vehicle.images.loading.length > 0) {
			return vehicle.images.loading[0].url
		}
		if (vehicle.images?.georgia && vehicle.images.georgia.length > 0) {
			return vehicle.images.georgia[0].url
		}
		
	}

	const handleSelectedImage = (url: string) => {
		setImage(url)
	}


	const Image = () => {
		return <Pane className="flex self-start justify-center items-center min-w-[500px] max-w-[500px]">
			{!image ? <Pane className="w-[200px] h-[200px] self-center rounded-lg flex justify-center items-center text-neutral-300">No Image</Pane> : <img src={image} alt="" className="flex justify-center items-center w-auto max-w-300 max-h-[500px] self-center rounded-lg"/>}
		</Pane>
	}

	const ImageList = () => {
			
		return <Pane className="flex flex-row flex-wrap self-start gap-4 ">
			{vehicle.images?.auction && vehicle.images.auction.map((image: any, index: number) => (
				<Pane className="w-auto h-[100px] self-center rounded-lg flex justify-center items-center cursor-pointer" key={index}>
					<img src={image.url} alt="" className="w-auto max-h-[100px] self-center rounded-lg" onClick={() => handleSelectedImage(image.url)}/>
				</Pane>
			))}

			{vehicle.images?.america && vehicle.images.america.map((image: any, index: number) => (
				<Pane className="w-auto h-[100px] self-center rounded-lg flex justify-center items-center cursor-pointer" key={index}>
					<img src={image.url} alt="" className="w-auto max-h-[100px] self-center rounded-lg" onClick={() => handleSelectedImage(image.url)}/>
				</Pane>
			))}

			{vehicle.images?.loading && vehicle.images.loading.map((image: any, index: number) => (
				<Pane className="w-auto h-[100px] self-center rounded-lg flex justify-center items-center cursor-pointer" key={index}>
					<img src={image.url} alt="" className="w-auto max-h-[100px] self-center rounded-lg" onClick={() => handleSelectedImage(image.url)}/>
				</Pane>
			))}

			{vehicle.images?.georgia && vehicle.images.georgia.map((image: any, index: number) => (
				<Pane className="w-auto h-[100px] self-center rounded-lg flex justify-center items-center cursor-pointer" key={index}>
					<img src={image.url} alt="" className="w-auto max-h-[100px] self-center rounded-lg" onClick={() => handleSelectedImage(image.url)}/>
				</Pane>
			))}
		</Pane>
	}

	const ImageContainer = () => {
		
		return <Pane className="flex flex-wrap gap-4">
				<Image />
				<ImageList />
		</Pane>
	}


	return (
		<Pane className="view-container">
			{loading ? <Pane className="bg-white p-5 rounded-lg flex gap-7 justify-center items-center"><Loading /></Pane> :
					<Pane className="bg-white p-5 rounded-lg flex gap-7">
						<Pane className="font-bold">{!loading && (!image ? <Pane className="bg-neutral-100 w-[100px] select-none h-[100px] rounded-lg flex justify-center items-center text-neutral-300">No Image</Pane> : <img src={getFirstImage()} alt="" className="w-[100px] select-none h-[100px] rounded-lg"/>)}</Pane>
						<Pane className="flex flex-col gap-2 justify-between">
							<Pane className="text-2xl self-start"><b>{vehicle?.brand?.name}</b> {vehicle?.model?.name} <u>{vehicle?.year}</u></Pane>
							<Pane className="flex flex-col gap-2 ">
								<Pane className="text-sm text-neutral-600"><b className="select-none">VIN:</b> {vehicle?.vin}</Pane>
								<Pane className="text-sm text-neutral-600"><b className="select-none">LOT:</b> {vehicle?.lot_number}</Pane>
							</Pane>
						</Pane>
						<Pane className="flex flex-col gap-2 justify-end">
							<Pane className="text-sm text-neutral-600"><b className="select-none">City:</b> {vehicle?.city}</Pane>
							<Pane className="text-sm text-neutral-600"><b className="select-none">Key:</b> {vehicle?.key ? 'Yes' : 'No'}</Pane>
						</Pane>
						<Pane>
						</Pane>
					</Pane>
				}

				{loading ? <Pane className="bg-white p-5 rounded-lg flex gap-7 justify-center items-center"><Loading /></Pane> :
					<Pane className="box bg-white p-5 rounded-lg flex gap-7 flex-row flex-wrap justify-between">
						<Pane className="box flex flex-col">
							<Pane className="label">Pay Date</Pane>
							<Pane className="text-xs">{vehicle?.pay_date}</Pane>
						</Pane>
						<Pane className="box flex flex-col">
							<Pane className="label">Pay Amount</Pane>
							<Pane className="text-xs">{vehicle?.pay_price ? '$'+vehicle?.pay_price : 'N/A'}</Pane>
						</Pane>
						<Pane className="box flex flex-col">
							<Pane className="label">Purchase Date</Pane>
							<Pane className="text-xs">{vehicle?.purchase_date ? vehicle?.purchase_date : 'N/A'}</Pane>
						</Pane>
						<Pane className="box flex flex-col">
							<Pane className="label">Purchase Amount</Pane>
							<Pane className="text-xs">{vehicle?.purchase_price ? '$'+vehicle?.purchase_price : 'N/A'}</Pane>
						</Pane>
					</Pane>
				}

				{loading ? <Pane className="bg-white p-5 rounded-lg flex gap-7 justify-center items-center"><Loading /></Pane> :
					<Pane className="box bg-white p-5 rounded-lg flex gap-7">
						<ImageContainer />
					</Pane>
				}
		</Pane>
	)
}

export default View