import { Card, Pane } from "evergreen-ui"
import "../index.css"
import logo from "../../assets/images/logo-light.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEarthAsia, faRightFromBracket,  faUsers } from '@fortawesome/free-solid-svg-icons';
import { PiNewspaperClippingFill} from "react-icons/pi";
import { FaCarAlt } from "react-icons/fa";
import { AiFillCalculator } from "react-icons/ai";

import { IoSettings } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutRequest } from "../../redux/actions/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { GlobalContext } from "providers/global.provider";

const SideBar = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const auth 		 = useSelector((state: any) => state.auth);
	const { role } 	 = useContext<any>(GlobalContext);
	
	// const [isMenuOpen, setIsMenuOpen] = useState(false);
	// const [activeMenuItem, setActiveMenuItem] = useState(0)

	const menuItems = [
		
		{
			label: 'Vehicles',
			icon: <FaCarAlt size={20} className="w-5" />,
			link: '/in/vehicles',
			blocked: false
		},
		{
			label: 'Users',
			icon: <FontAwesomeIcon icon={faUsers} className="w-5" />,
			link: '/in/users',
			blocked: false
		},
		{
			label: 'Auctions',
			icon: <FontAwesomeIcon icon={faEarthAsia} className="w-5" />,
			link: '/in/auctions',
			blocked: false
		}
	]

	const DealerMenuItems = [
		{
			label: 'News and Updates',
			icon: <PiNewspaperClippingFill size={20} className="w-5" />,
			link: '/in/news-and-updates',
			blocked: true,
			redirectTo: '/in/my-vehicles'
		},
		{
			label: 'My Vehicles',
			icon: <FaCarAlt size={20} className="w-5" />,
			link: '/in/my-vehicles',
			blocked: false,
			permission: ['Dealer']
		},
		{
			label: 'Calculator',
			icon: <AiFillCalculator size={20} className="w-5" />,
			link: '/in/calculator',
			blocked: false
		}
	]

	const settingsMenuItems = [
		{
			label: 'Settings',
			icon: <IoSettings size={20} className="w-5" />,
			link: '/in/settings',
			blocked: true
		},
		{
			label: 'Logout',
			icon: <FontAwesomeIcon icon={faRightFromBracket} className="w-5" />,
			link: '/in/logout',
			blocked: false
		}
	]

	useEffect(() => {
		if(location.pathname === '/in/logout') {
			logoutRequest()(dispatch, navigate);
		}
	}, [location.pathname, dispatch, navigate])

	useEffect(() => {
		// menuItems.forEach((item, index) => {
		// 	if(item.redirectTo){
		// 		if(location.pathname === item.link) {
		// 			navigate(item.redirectTo)
		// 		}
		// 	}
		// })

		if(location.pathname === '/in') {
			role.isAM() && navigate('/in/auctions')
			role.isDealer() && navigate('/in/news-and-updates')
		}

		if(role.isDealer()){
			if(DealerMenuItems[0].redirectTo){
				if(location.pathname === DealerMenuItems[0].link) {
					navigate(DealerMenuItems[0].redirectTo)
				}
			}else{
				navigate('/in/news-and-updates')
			}
		}
	})


	const MenuItemsList = menuItems.map((item, index) => (
		
		<Pane
			className={`group sidebar-link flex flex-row items-center gap-4 text-sm ${item.blocked ? 'blocked' : ''}  text-slate-700/85 hover:text-slate-600 ${location.pathname === item.link ? 'active' : ''}`}
			key={index}
			onClick={() => item.blocked ? null : handleMenuClick(item.link)}
		>
			{item.icon}
			{item.label}
		</Pane>
	))

	const DealerMenuItemsList = DealerMenuItems.map((item, index) => {

		if(item.permission) {
			if(!item.permission.includes(auth.user.role.title)) {
				return null
			}
		}

		return <Pane
			className={`group sidebar-link flex flex-row items-center gap-4 text-sm ${item.blocked ? 'blocked' : ''}  text-slate-700/85 hover:text-slate-600 ${location.pathname === item.link ? 'active' : ''}`}
			key={index}
			onClick={() => item.blocked ? null : handleMenuClick(item.link)}
		>
			{item.icon}
			{item.label}
		</Pane>
})

	const SettingsMenuItemsList = settingsMenuItems.map((item, index) => (
		<Pane
			className={`group sidebar-link flex flex-row items-center gap-4 text-sm ${item.blocked ? 'blocked' : ''}  text-slate-700/85 hover:text-slate-600 ${location.pathname === item.link ? 'active' : ''}`}
			key={index}
			onClick={() => item.blocked ? null : handleMenuClick(item.link)}
		>
			{item.icon}
			{item.label}
		</Pane>
	))

	const handleMenuClick = (link: string) => {
		navigate(link)
	}

	return (
		<Pane className="sidebar sidebar-min">
			<Card className="sidebar-bubble">
				<Pane className="sidebar-logo ">
					<img src={logo} alt="logo" className="cursor-pointer" onClick={() => navigate('/')} />
				</Pane>

				{role.isAM() && (
					<Pane className="flex flex-col mt-5  sidebar-menu">
						<Pane className="sidebar-header">Manager</Pane>
						{MenuItemsList}
					</Pane>
				)}

				<Pane className="flex flex-col h-full sidebar-menu">
					<Pane className="sidebar-header">Dealer</Pane>
						{DealerMenuItemsList}
				</Pane>

				<Pane className="flex flex-col mt-5 sidebar-menu">
					<Pane className="sidebar-header">Settings</Pane>
					{SettingsMenuItemsList}
				</Pane>
			</Card>
		</Pane>
	)
}

export default SideBar