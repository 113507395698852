import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorage } from 'utils/helpers/LocalStorage.helper';

interface AuthState {
  isAuthenticated: boolean;
  user: { 
    id: number
    name: string
    surname: string
    email: string
    phone: string
    address: string
    avatar: string  
    role: { id: number, title: string }
    activated: boolean
    archived: boolean
    username: string 
  } | {};
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: {},
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: any) => {
      state.isAuthenticated = true;
      state.user = action.payload;

      LocalStorage.set('role', action.payload.role.id)
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.user = {};
      state.isAuthenticated = false;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.user = {};
      LocalStorage.remove('role')
      LocalStorage.remove('token')

    }
  },
});

export const { loginSuccess, logoutSuccess, loginFailure } = authSlice.actions;
export default authSlice.reducer;