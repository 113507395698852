import { Dispatch } from "@reduxjs/toolkit"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"



export const getCalculatorText = () => (dispatch: Dispatch) => {

		dispatch({
			type: 'calculator/message',
			payload: {	
				type: 'loading',
				data: true
			}
		})
		
		request.get('/api/v1/get-calculator-text', {
			headers: {
				Authorization: LocalStorage.get('token')
			}
		}).then(res => {
			dispatch({
				type: 'calculator/message',
				payload: {
					type: 'set',
					data: res.data.data
				}
			})
		}).catch(err => {
			console.log(err)
		})
	
}


export const updateCalculatorText = (text: string) => (dispatch: Dispatch) => {
	
	dispatch({
		type: 'calculator/message',
		payload: {	
			type: 'loading',
			data: true
		}
	})

	request.post('/api/v1/update-calculator-text', {
		text: text
	}).then(res => {
		console.log(res)
		dispatch({
			type: 'calculator/message',
			payload: {
				type: 'set',
				data: res.data.data
			}
		})
	}).catch(err => {
		dispatch({
			type: 'calculator/message',
			payload: {	
				type: 'loading',
				data: false
			}
		})
	})

}

export const getAuctions = () => (dispatch: Dispatch) => {
	
	dispatch({
		type: 'calculator/auctions',
		payload: {	
			type: 'loading',
			data: true
		}
	})

	request.get('/api/v1/calculator/get-auctions', {
		headers: {
			Authorization: LocalStorage.get('token')
		}
	}).then(res => {
		dispatch({
			type: 'calculator/auctions',
			payload: {
				type: 'set',
				data: res.data.data
			}
		})
	}).catch(err => {
		dispatch({
			type: 'calculator/auctions',
			payload: {	
				type: 'loading',
				data: false
			}
		})
	})
}

export const getStates = (auctionId: any) => (dispatch: Dispatch) => {
	
	dispatch({
		type: 'calculator/states',
		payload: {	
			type: 'loading',
			data: true
		}
	})

	request.get(`/api/v1/calculator/get-auction-states?id=${auctionId}`, {
		headers: {
			Authorization: LocalStorage.get('token')
		}
	}).then(res => {
		dispatch({
			type: 'calculator/states',
			payload: {
				type: 'set',
				data: res.data.data
			}
		})
	}).catch(err => {
		dispatch({
			type: 'calculator/states',
			payload: {	
				type: 'loading',
				data: false
			}
		})
	})
}

export const getTypes = () => (dispatch: Dispatch) => {
	
	dispatch({
		type: 'calculator/types',
		payload: {	
			type: 'loading',
			data: true
		}
	})

	request.get('/api/v1/calculator/get-auction-vehicle-types', {
		headers: {
			Authorization: LocalStorage.get('token')
		}
	}).then(res => {
		dispatch({
			type: 'calculator/types',
			payload: {
				type: 'set',
				data: res.data.data
			}
		})
	}).catch(err => {
		dispatch({
			type: 'calculator/types',
			payload: {	
				type: 'loading',
				data: false
			}
		})
	})
}

export const getCalculatedPrice = (auctionId: any, stateId: any, typeId: any) => (dispatch: Dispatch) => {
	
	dispatch({
		type: 'calculator/price',
		payload: {	
			type: 'loading',
			data: true
		}
	})

	console.log(auctionId, stateId, typeId)

	request.get(`/api/v1/calculator/get-price?auction_id=${auctionId}&state_id=${stateId}&type_id=${typeId}`, {
		headers: {
			Authorization: LocalStorage.get('token')
		}
	}).then(res => {
		dispatch({
			type: 'calculator/price',
			payload: {
				type: 'set',
				data: res.data.data
			}
		})
	}).catch(err => {
		dispatch({
			type: 'calculator/price',
			payload: {	
				type: 'loading',
				data: false
			}
		})
	})
}	