// Copart Vehicle Reducer
import { createSlice } from "@reduxjs/toolkit";

const Vehicles = createSlice({
	name: 'vehicles',
	initialState: {
		loading: false,
		list: [],
		view: {},
		loadingUpdated: false,
		error: {
			status: false,
			message: null,
			errors: null
		}
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload
			state.error.status = false;
		},
		setVehicles: (state, action) => {
			state.loading = false
			state.list = action.payload
			state.error = {
				status: false,
				message: null,
				errors: null
			}
		},
		setView: (state, action) => {
			state.view = action.payload
			state.error = {
				status: false,
				message: null,
				errors: null
			}
			state.loading = false
		},
		setError: (state, action) => {
			state.error = action.payload
			state.error.status = true
			state.loading = false
		}
	}
})

export const { setVehicles, setLoading, setError, setView } = Vehicles.actions
export default Vehicles.reducer