// AuthContext.tsx
import { EmptyState, LockIcon, Pane } from 'evergreen-ui';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { IsAuthenticated} from '../redux/selectors/authSelectors';
import { useNavigate } from 'react-router-dom';
import SideBar from '../components/common/SideBar';

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

	const isAuthenticated = useSelector(IsAuthenticated);
	const navigate      = useNavigate();

	const NotAuthorized = () => {
		return (
			<EmptyState
			background="light"
			title="თქვენ არ ხართ ავტორიზებული"
			orientation="vertical"
			icon={<LockIcon color="#dc2828" />}
			iconBgColor="#F8E3DA"
			description="თუ გსურთ გვერდის გახსნა, გთხოვთ გაიაროთ ავტორიზაცია"
			primaryCta={<EmptyState.PrimaryButton className='!bg-red-500 !border-red-500 hover:!bg-red-600 hover:!border-red-600'  onClick={() => navigate('/')}>ავტორიზაცია</EmptyState.PrimaryButton>}
	/>
		)
}

	if(isAuthenticated){
		return (
			<Pane className="container-in">
				<SideBar />
				<Pane>
      		{ children }
				</Pane>
			</Pane>
  	);
	}else{
		
		return (
			<Pane>
				<NotAuthorized />
			</Pane>
		);
		
		
	}
  
};


export default AuthProvider