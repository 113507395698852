import axios, { AxiosError } from "axios";
import { LocalStorage } from "../helpers/LocalStorage.helper";

const token = LocalStorage.get('token');

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 100000,
    headers: {
        common: {
            "Accept": "application/json",
        },
        Authorization: token
    },
})

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 401) {
            LocalStorage.remove('token');
            window.location.href = '/auth';
        }
        return response;
    },
    (error: AxiosError) => {
        console.log(error);
        console.error("Error:", error);

        if (error.response && error.response.status === 422) {
            LocalStorage.remove('token');
            window.location.href = '/auth';
        }

        return Promise.reject(error);
    }
);

export const request = axiosInstance;