// Copart Vehicle Reducer
import { createSlice } from "@reduxjs/toolkit";

const calculatorSlice = createSlice({
	name: 'calculator',
	initialState: {
		loading: false,
		auctions: {
			loading: false,
			data: [],
		},
		states: {
			loading: false,
			data: [],
		},
		types: {
			loading: false,
			data: [],
		},
		price: {
			loading: false,
			data: ''
		},
		message:{
			loading: false,
			text: '',
			error: ''
		}
	},
	reducers: {
		message: (state, action) => {
			switch(action.payload.type){
				case 'loading':
					state.message.loading = action.payload.data
				break;
				case 'set':
					state.message.loading = false
					if(action.payload.data.text !== ''){
						state.message.text = action.payload.data.text
					}
				break;
				case 'error':
					state.message.loading = false
				break;
			}
		},
		auctions: (state, action) => {
			switch(action.payload.type){
				case 'loading':
					state.auctions.loading = action.payload.data
				break;
				case 'set':
					state.auctions.loading = false
					state.auctions.data = action.payload.data
				break;
				case 'error':
					state.message.loading = false
				break;
			}
		},
		states: (state, action) => {
			switch(action.payload.type){
				case 'loading':
					state.states.loading = action.payload.data
				break;
				case 'set':
					state.states.loading = false
					state.states.data = action.payload.data
				break;
				case 'error':
					state.message.loading = false
				break;
			}
		},
		types: (state, action) => {
			switch(action.payload.type){
				case 'loading':
					state.types.loading = action.payload.data
				break;
				case 'set':
					state.types.loading = false
					state.types.data = action.payload.data
				break;
				case 'error':
					state.message.loading = false
				break;
			}
		},
		price: (state, action) => {
			switch(action.payload.type){
				case 'loading':
					state.price.loading = action.payload.data
				break;
				case 'set':
					state.price.loading = false
					state.price.data = action.payload.data.price
				break;
				case 'error':
					state.message.loading = false
				break;
			}
		}
	}
})

export const { message, auctions, states, types } = calculatorSlice.actions
export default calculatorSlice.reducer