import FilterTab from "components/features/FilterTab";
import {
  Heading,
  InfoSignIcon,
  Pagination,
  Pane,
  Text,
  Tooltip,
} from "evergreen-ui";
import React, { useEffect, useState } from "react";
import "./index.css";
import { FwDataTable } from "@freshworks/crayons/react";
import { getAuctionVehicles } from "../../../redux/actions/auction.action";
import { useDispatch, useSelector } from "react-redux";
import AuctionUpdateTime from "components/features/AuctionUpdateTime/AuctionUpdateTime";
import { IoCopyOutline } from "react-icons/io5";
import Loading from "components/ui/Loading";
import { format } from "date-fns";

type Bids = {
  event: string;
  bid_amount: string;
  vin: string | null;
  datetime: string;
  date: string;
  time: string;
  user_ip: string;
  user_country: string;
  user_city: string;
  accepted: number;
  brand: string;
  model: string;
  year: string;
  key: number;
  lot_sold: number;
  highest_bid: string;
  dealer: {
    name: string;
    surname: string;
  };
};

type Vehicle = {
  lot_number: string;
  lot_sold: number;
  win: number;
  lost: number;
  total: number;
  totalWin: number;
  totalLost: number;
  totalPending: number;
  totalAccepted: number;
  listed: number;
  update_at_time: string;
  time_diff: string;
  bids: Bids[];
};

const Auctions: React.FC = () => {
  const dispatch = useDispatch();

  // Redux
  const auction = useSelector((state: any) => state.auction);
  const vehicles: Vehicle[] = auction.list;

  // Pagination
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const itemsPerPage = 20;

	// tools
	const [copyText, setCopyText] = useState('Copy');

  // // Filter
  // const [query, setQuery] = React.useState<string>('')
  // const [status, setStatus] = React.useState<string>('')
  // const [oldest, setOldest] = React.useState<string>('')

  // const [pendingCount, setPendingCount] = React.useState<number>(0)
  // const [wonCount, setWonCount] = React.useState<number>(0)
  // const [lostCount, setLostCount] = React.useState<number>(0)
  const [totalCount, setTotalCount] = React.useState<number>(0);
	const [activeTab, setActiveTab] = useState(0)


  useEffect(() => {
    setTotalCount(auction.count);
  }, [auction]);

  // useEffect(() => {
  // 	setPendingCount(auction.pending);
  // 	setWonCount(auction.won);
  // 	setLostCount(auction.lost);
  // }, [auction]);

  // useEffect(() => {
  //   if(query.length > 3) {
  //     getAuctionVehicles(currentPage, status, oldest, query)(dispatch);
  //   }else if(query.length === 0) {
  //     getAuctionVehicles(currentPage, status, oldest)(dispatch);
  //   }

  // }, [dispatch, status, oldest, query, currentPage]);

  // const tableRef = useRef();
  const [visibleBids, setVisibleBids] = useState<Record<string, boolean>>({});

  const toggleBidsVisibility = (e: any, lotNumber: string) => {
    e.preventDefault();
    e.stopPropagation();

    setVisibleBids((prevVisibleBids: any) => ({
      // ...prevVisibleBids,
      [lotNumber]: !prevVisibleBids[lotNumber],
    }));
  };

  useEffect(() => {
    getAuctionVehicles(1, 0, false, activeTab)(dispatch);
    setTotalCount(auction.count);
  }, [activeTab]); // eslint-disable-line

  const GetBids = ({ bids }: { bids: Bids[] }) => {
    return (
      <FwDataTable
        columns={[
          {
            key: "event",
            text: "Event",
          },
          {
            key: "bid_amount",
            text: "Bid Amount",
          },
          {
            key: "time",
            text: "Time",
          },
          {
            key: "user_ip",
            text: "User IP",
          },
          {
            key: "user_country",
            text: "User Country",
          },
          {
            key: "dealer",
            text: "Dealer",
          },
        ]}
        rows={bids.map((item: Bids) => ({
          event: item.event,
          bid_amount: item.bid_amount,
          time: item.datetime,
          user_ip: item.user_ip,
          user_country: `${item.user_country} / ${item.user_city}`,
          dealer: item.dealer
            ? `${item.dealer.name} ${item.dealer.surname}`
            : "",
        }))}
        className="!text-xs"
      />
    );
  };

  const vehicleList = vehicles.map((vehicle: Vehicle, index) => {
    return (
      <Pane className="flex flex-col gap-2 text-xs">
        <Pane
          className={`item  ${
            vehicle.lot_sold === 0
              ? " !bg-yellow-300/50"
              : vehicle.win
              ? " !bg-green-300/50"
              : " !bg-red-300/50"
          } hover:!brightness-90 hover:!scale-105 !transition-transform`}
          data-status={
            vehicle.win
              ? "WON"
              : vehicle.win === 0 && vehicle.lost === 0
              ? "Pending"
              : "Lost"
          }
          key={index}
          onClick={(e: any) => toggleBidsVisibility(e, vehicle.lot_number)}
        >
          <Pane className="lot !w-24 flex flex-col gap-1">
            <Heading className="!font-poppins !text-xs uppercase flex gap-1 items-center">
              LOT
            </Heading>
            <Tooltip
              content="Redirect to Auction"
              appearance="card"
              position="top"
            >
              <Text
                className="!font-poppins !text-xs !underline "
                onClick={(e: any) =>{

                  if(activeTab === 0){
                    window.open(
                      `https://www.copart.com/lot/${vehicle.lot_number}`
                    )
                  }else{
                    window.open(
                      `https://www.iaai.com/VehicleDetail/${vehicle.lot_number}~US`
                    )
                    
                }
              }
                
                }
              >
                {vehicle.lot_number}
              </Text>
            </Tooltip>
          </Pane>
          <Pane className="name !w-44 flex flex-col gap-1">
            <Heading className="!font-poppins !text-xs uppercase flex gap-1 items-center">
              Name
              <Tooltip
                content="Will be shown when first update was made"
                appearance="card"
                position="top"
              >
                <InfoSignIcon className="!w-3 !h-3" />
              </Tooltip>
            </Heading>
            <Text className="!font-poppins !text-xs " >
              {vehicle.bids[0].year ? vehicle.bids[0].year : "N/A"} {vehicle.bids[0].brand} {vehicle.bids[0].model}
            </Text>
          </Pane>
          <Pane className="vin !w-32 flex flex-col gap-1 relative">
            <Heading className="!font-poppins !text-xs uppercase flex gap-1 items-center">
              VIN
              {vehicle.bids[0].vin && (
                <Tooltip content={copyText} appearance="card" position="bottom">
                  <Text>
                    <IoCopyOutline
                      className="!w-4 !h-4 p-[2px] hover:bg-white/30 rounded-sm cursor-pointer"
                      onClick={(e: any) => {
                        e.preventDefault();
												e.stopPropagation();
                        navigator.clipboard.writeText(
                          vehicle.bids[0].vin ? vehicle.bids[0].vin : "N/A"
                        );
												setCopyText("Copied");
												setTimeout(() => setCopyText("Copy"), 2000);
                      }}
                    />
                  </Text>
                </Tooltip>
              )}
            </Heading>
            <Text className="!font-poppins !text-xs " >
              {vehicle.bids[0].vin ? vehicle.bids[0].vin : "N/A"}
            </Text>
          </Pane>
          <Pane className="last-action !w-44 flex flex-col gap-1">
            <Heading className="!font-poppins !text-xs uppercase">
              Last Action
            </Heading>
            <Text className="!font-poppins !text-xs " >
              {vehicle.bids[vehicle.bids.length - 1].event}
            </Text>
          </Pane>
          <Pane className="last-bid !w-22 flex flex-col gap-1">
            <Heading className="!font-poppins !text-xs uppercase">
              last Bid
            </Heading>
            <Text className="!font-poppins !text-xs " >
              {vehicle.bids[vehicle.bids.length - 1].bid_amount} ({format(vehicle.bids[vehicle.bids.length - 1].date, "dd/MM/yyyy")})
            </Text>
          </Pane>
          {vehicle.lot_sold === 0 && (
            <Pane className="flex flex-col gap-1">
              <Heading className="!font-poppins !text-xs uppercase">
                Last Updated
              </Heading>
              <Text className="!font-poppins !text-xs " >
                {vehicle.update_at_time} ({vehicle.time_diff})
              </Text>
            </Pane>
          )}
          {vehicle.lot_sold === 0 && (
            <Pane className="copart-row-time">
              <AuctionUpdateTime updateTime={vehicle.update_at_time} />
            </Pane>
          )}
        </Pane>
        {(visibleBids[vehicle.lot_number] as boolean) && (
          <GetBids bids={vehicle.bids} />
        )}
      </Pane>
    );
  });

  const EmptyList = () => {
    return (
      <Pane className="empty-list">
        <Pane className="lot">No Vehicles</Pane>
      </Pane>
    );
  };

  

  return (
    <Pane className="mt-8 flex flex-col gap-5 ">
      <FilterTab activeTab={activeTab} setActiveTab={setActiveTab} />
      <Pane className="vehicles bg-white p-5 rounded-md shadow-sm">
        <Pane className="label">Vehicles</Pane>
          {vehicles.length > 0 ? (
            vehicleList
          ) : auction.loading ? (
            <Loading />
          ) : (
            <EmptyList />
          )}
          <Pagination
            className="mt-4 max-md:hidden [&>ul]:flex [&>ul]:flex-wrap self-end pt-4 [&>*]:gap-1"
            page={currentPage}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPreviousPage={() => setCurrentPage(currentPage - 1)}
            totalPages={Math.ceil(totalCount / itemsPerPage)}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          ></Pagination>
        </Pane>

    </Pane>
  );
};

export default Auctions;
