import { Pane } from 'evergreen-ui'

function Dashboard() {
	return (
		<Pane>
			<Pane className="text-xs text-neutral-400">Redirecting...</Pane>
		</Pane>
	)
}

export default Dashboard