import {  Button,  Label,  Pane, Popover, SelectMenu, TextInputField, Tooltip,majorScale } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { createUser, getUserById, getUserGroups, updateUser } from "../../../redux/actions/user.action";
import Loading from "components/ui/Loading";
import { PiCaretCircleLeftDuotone } from "react-icons/pi";
import { createUserState } from "../../../redux/reducers/user.reducer";
import { UserError } from "../../../redux/selectors/userSelectors";

const AddUpdateUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
	const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.updateUser);
  const userGroups = useSelector((state: any) => state.user.groups);
  const createUserMessage = useSelector((state: any) => state.user.createUserMessage);
  const loading = useSelector((state: any) => state.user.loadingUpdated);
	const error = useSelector(UserError);

  // USER FORM
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
	const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [group, setGroup] = useState<any>({})


	const [changeList, setChangeList] = useState<any>([]); // eslint-disable-line

	useEffect(() => {
		getUserGroups()(dispatch);
	}, []);

  useEffect(() => {
		if(id){
			getUserById(id)(dispatch);
		}else{
			dispatch(createUserState())
		}
  }, [id]); // eslint-disable-line

  useEffect(() => {
    setName(user?.name || "");
    setSurname(user?.surname || "");
		setUsername(user?.username || "");
    setEmail(user?.email || "");
    setPhone(user?.phone || "");
    setAddress(user?.address || "");
		setGroup({ value: user?.group_id, label: user?.group?.title} || {});
  }, [user]);


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "name") setName(value);
    if (name === "surname") setSurname(value);
    if (name === "username") setUsername(value);
    if (name === "email") setEmail(value);
    if (name === "phone") setPhone(value);
    if (name === "address") setAddress(value);
    if (name === "password") setPassword(value);

    // setChangeList({ ...changeList, [name]: value });

  };

	const onSubmit = () => {

		if(id){

            // UPDATE
            const data = {}
            name !== user?.name && Object.assign(data, { name })
            surname !== user?.surname && Object.assign(data, { surname })
            address !== user?.address && address !== "" && Object.assign(data, { address })
            phone !== user?.phone && phone !== "" && Object.assign(data, { phone })
            email !== user?.email && email !== "" && Object.assign(data, { email })
						group?.value !== user?.group?.id && Object.assign(data, { group_id: group?.value })

            username !== user?.username && Object.assign(data, { username })
            
            changeList.password && Object.assign(data, { password, password_confirmation: password })
            
            updateUser(data, id)(dispatch);

		}else{

            // CREATE
            const data = {
                name: name,
                surname: surname,
                address: address,
                phone: phone,
                email: email,
                username: username,
                role_id: 3,
                password: password,
                password_confirmation: password,
                activated: 1,
                archived: 0,
								group_id: group?.value
			}

			createUser(data)(dispatch);
			
			navigate('/in/users');
		}
	}

  return (
    <Pane className="mt-8 flex flex-col gap-5">
      <Pane className="box bg-white p-5 rounded-md shadow-sm">
        <Pane className="label flex gap-2 items-center  justify-between">
					<Pane className="flex flex-row gap-2 items-center">
						<Pane><PiCaretCircleLeftDuotone size={18} className="cursor-pointer" onClick={() => window.history.back()} /></Pane>
						<Popover
								position="bottom-left"
								minWidth={majorScale(2)}
								content={<Pane className="p-5 flex flex-col gap-5 !text-xs w-28">
									<Pane className="flex flex-row gap-3 items-center cursor-pointer">
										<Pane className="w-2 h-2 status-orange rounded-full flex items-center justify-center" />
										Archive
									</Pane>
									<Pane className="flex flex-row gap-3 items-center cursor-pointer">
										<Pane className="w-2 h-2 status-red rounded-full flex items-center justify-center" />
										Delete
									</Pane>
									<Pane className="flex flex-row gap-3 items-center cursor-pointer">
										<Pane className="w-2 h-2 status-green rounded-full flex items-center justify-center" />
										Active
									</Pane>
								</Pane>}
							>
									{user?.archived ? (
												<Pane className="flex flex-row gap-3 items-center cursor-pointer">
														<Tooltip content="Archived" position="top">
															<Pane className="w-2 h-2 status-orange rounded-full flex items-center justify-center" />
														</Tooltip>
												</Pane>
										) : !user?.activated ? (
												<Pane className={`flex flex-row gap-3 items-center cursor-pointer ${id ? "" : "hidden"}`}>
														<Tooltip content="Deleted" position="top">
															<Pane className="w-2 h-2 status-red rounded-full flex items-center justify-center" />
														</Tooltip>
												</Pane>
										) : (
												<Pane className="flex flex-row gap-3 items-center cursor-pointer">
														<Tooltip content="Operating" position="top">
															<Pane className="w-2 h-2 status-green rounded-full flex items-center justify-center" />
														</Tooltip>
												</Pane>
										) }
							</Popover>
						{id ?  "Update User: " : "Create User: "}
						<b>
							{name} {surname}
						</b>
					</Pane>
					<Pane className="cursor-pointer flex justify-end">
						<Pane className="underline hover:text-red-600 font-bold">{id ? <Button intent="info" onClick={onSubmit}>Make Update</Button> : <Button intent="success" className="!bg-[#25e51e] hover:!bg-[#25e51e]/80 !text-black" onClick={onSubmit}>Create User</Button>}</Pane>
					</Pane>

        </Pane>
        {loading ? <Loading /> : 
				
				<Pane>
					<Pane className={`mb-5 ${error.status ? "block" : "hidden"}`}>
						<div className="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
							<div className="flex items-center">
								<span className="sr-only">Info</span>
								<h3 className="text-lg font-medium">{error.message}</h3>
							</div>
							<div className="text-sm">
								{error.status && Object.keys(error.errors).map((key, index) => {
									return <p key={index}>{error.errors[key][0]}</p>
								})}
							</div>
						</div>
					</Pane>
					<Pane className={`mb-5 ${createUserMessage ? "block" : "hidden"}`}>
						<div className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 " role="alert">
							<div className="flex items-center">
								<span className="sr-only">Info</span>
								<h3 className="text-lg font-medium">{createUserMessage && "Message"}</h3>
							</div>
							<div className="text-sm">
								{createUserMessage && <p>{createUserMessage}</p>}
							</div>
						</div>
					</Pane>
					<Pane className="grid grid-cols-4 max-xl:grid-cols-3  max-lg:grid-cols-2 max- gap-5 max-md:grid-cols-1">
							<TextInputField
								label="Name"
								name="name"
								placeholder="Name"
								value={name}
								onChange={onChange}
							/>
							<TextInputField
								label="Surname"
								name="surname"
								placeholder="Surname"
								value={surname}
								onChange={onChange}
								/>
							<TextInputField
								label="Email"
								name="email"
								type="email"
								placeholder="Email"
								value={email}
								onChange={onChange}
								/>
							<TextInputField
								label="Phone"
								name="phone"
								type="tel"
								placeholder="Phone"
								value={phone}
								onChange={onChange}
								/>
							<TextInputField
								label="Address"
								name="address"
								placeholder="Address"
								value={address}
								onChange={onChange}
								/>
							<TextInputField
								label="Role"
								name="role"
								placeholder="Role"
								value={user?.role.title || "Dealer"}
								disabled
							/>
							<Pane className="flex flex-col gap-2">
                        <Label>Group</Label>
												<SelectMenu
														title="Select Group"
														onFilterChange={(filter) => (filter)}
														height={200}
														selected={group}
														options={userGroups.list.map((item:any): { value: number; label?: JSX.Element } => ({ value: item.id, label: <Pane className="flex flex-row justify-between w-full content-between items-center">{item.name} <Pane className="italic text-neutral-400 !text-xs text-right">{item.price} GEL</Pane></Pane> }))} // `${item.name} <i>${item.price} USD</i>`
														onSelect={(item) => setGroup(item)}
												>
														<Button>{group?.label || 'Select Group'}</Button>
												</SelectMenu>
							</Pane>

					</Pane>
					<Pane>
						<hr className="h-px my-8 mb-0  bg-gray-200 border-0  shadow-md"></hr>
					</Pane>
					<Pane className="grid grid-cols-4 max-xl:grid-cols-3  max-lg:grid-cols-2 max- gap-5 max-md:grid-cols-1 bg-gray-200/50 shadow-md p-5">
						<TextInputField
							label="Username"
							name="username"
							type="text"
							placeholder="Username"
							value={username}
							onChange={onChange}
						/>
						<TextInputField
							label={id ? "New Password" : "Password"}
							name="password"
							type="password"
							placeholder={id ? "New Password" : "Password"}
							value={password}
							onChange={ onChange}
						/>
						</Pane>
					</Pane>
				}
      </Pane>
    </Pane>
  );
};

export default AddUpdateUser;
