import { create, models, dealers, brands } from "../reducers/allData.reducer"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"


export const getDealers = () => async (dispatch: any) => {
	
	const token = LocalStorage.get('token')

	if(token) {

		dispatch(dealers({type: 'loading', loading: true}))

		request.get('/api/v1/users/dealers', {
			headers: {
				Authorization: token
			}
		})
		.then(res => {
			dispatch(dealers({type: 'success', data: res.data.data.dealers}))
		})
		.catch(err => {
			dispatch(dealers({type: 'error', data: err}))
		})
	}
}

export const getBrands = () => async (dispatch: any) => {
	
	const token = LocalStorage.get('token')

	if(token) {

		dispatch(brands({ type: 'loading', loading: true }))

			request.get('/api/v1/car/brands', {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				dispatch(brands({type: 'success', data: res.data.data, loading: false }))
			})
			.catch(err => {
				dispatch(brands({type: 'error', data: err}))
			})
		} 
}




export const getModels = (brandId: any) => (dispatch: any) => {
    
	const token = LocalStorage.get('token')
	dispatch(models({ type: 'loading', loading: true }))

	request.get(`/api/v1/car/brands/${brandId}/model`, {
			headers: {
					Authorization: token
			}
	})
			.then(res => {
					dispatch(models({ type : 'success', data: res.data.data, loading: false }))
			})
			.catch(err => {
					dispatch(models({ type: 'error', data: err }))
			})
}


export const createVehicle = (data: any) => (dispatch: any) => {
	
	dispatch(create({ type: 'loading', loading: true, data: data }))
	const token = LocalStorage.get('token')

	request.post('/api/v1/car/create', {
			...data
	}, {
			headers: {
					"Content-Type": "multipart/form-data",
					Authorization: token
			}
	})
	.then(res => {
			dispatch(create({ type : 'success', data: res.data.data, message: res.data.message, loading: false }))
	})
	.catch(err => {
			dispatch(create({ type: 'error', data: err }))
	})

}


