import { useSelector } from "react-redux";
import { roleSelector } from "../../redux/selectors/authSelectors";

export function RoleValidation() {

    const role = useSelector(roleSelector);

    function isAdmin() {
        return role && parseInt(role.id) === 1;
    }

    function isManager() {
        return role && parseInt(role.id) === 2;
    }

    function isDealer() {
        return role && parseInt(role.id) === 3;
    }

    function isClient() {
        return role && parseInt(role.id) === 4;
    }

    function isAM() {
        return role && (parseInt(role.id) === 1 || parseInt(role.id) === 2)
    }

    return {
        isAM,
        isAdmin,
        isManager,
        isDealer,
        isClient,
    };

}

// export function HasPermission(path) {
//     const pages = useSelector(state => state.global.pages);
    
//     return Object
//             .keys(pages)
//             .some(key => 
//                     path === pages[key].path[0] 
//                     && pages[key].locked
//                 );

// }