import { setAuctionVehicles, setLoading } from "../reducers/auction.reducer"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"



export const getAuctionVehicles = (currentPage = 1, status: number, oldest: boolean, auction: number, query = '') => async (dispatch: any) => {

	const token = LocalStorage.get('token')

	dispatch(setLoading(true))
	let endpoint;

	if(auction === 0){
		endpoint = 'copart-vehicles'
	}else{
		endpoint = 'iaai-vehicles'
	}

	return request.get(`/api/v1/car/${endpoint}?page=${currentPage}&status=${status}&oldest=${oldest}${query.length > 3 ? `&query=${query}` : ''}`, {
		headers: {
			Authorization: token
	}
	}).then(res => {
		dispatch(setAuctionVehicles(res.data.data))
	}).catch(err => {
		return err
	})

}