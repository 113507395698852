import React, { useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthView from './containers/auth';
import AuthProvider from './providers/auth.provider';
import Dashboard from './containers/in/dashboard';
import { LocalStorage } from './utils/helpers/LocalStorage.helper';
import { authValidation } from './redux/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { IsAuthenticated } from './redux/selectors/authSelectors';
import logo from './assets/images/logo.png';
import Auctions from 'containers/in/auctions';
import ExtensionPrivacy from 'containers/Privacy';
import Users from 'containers/in/users';
import AddUpdateUser from 'containers/in/users/addUpdateUser';
import MyVehicles from 'containers/in/myVehicles';
import Vehicles from 'containers/in/vehicles';
import View from 'containers/in/vehicles/view';
import CreateVehicle from 'containers/in/vehicles/create';
import MainContainer from 'containers/main';
import Calculator from 'containers/in/calculator';

const WebRoutes: React.FC = () => {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(IsAuthenticated);
  const token = LocalStorage.get('token')

  // Redirect to auth
  useEffect(() => {
    const tokenStorage      = LocalStorage.get('token');
    tokenStorage && authValidation()(dispatch)
  }, [dispatch]);

  return (
    <Router>
      <Pane>
        {(token && !isAuthenticated) ? (
              <Pane className="w-screen h-screen flex items-center justify-center">
                <img src={logo} alt="logo" className="logo rounded-full w-[150px] h-auto" />
              </Pane>
        ) : 
          <Routes>
              <Route path="/" element={ <MainContainer /> } />
              <Route path="/auth" element={ <AuthView /> } />
              <Route path="/in" element={ <AuthProvider><Dashboard /></AuthProvider> } />
              <Route path="/in/news-and-updates" element={ <AuthProvider><Dashboard /></AuthProvider> } />
              <Route path="/in/vehicles" element={ <AuthProvider><Vehicles /></AuthProvider> } />
              <Route path="/in/vehicles/create" element={ <AuthProvider><CreateVehicle /></AuthProvider> } />
              <Route path="/in/vehicles/:id" element={ <AuthProvider><View /></AuthProvider> } />
              <Route path="/in/my-vehicles" element={ <AuthProvider><MyVehicles /></AuthProvider> } />
              <Route path="/in/calculator" element={ <AuthProvider><Calculator /></AuthProvider> } />
              <Route path="/in/calculator" element={ <AuthProvider><Calculator /></AuthProvider> } />
              <Route path="/in/users" element={ <AuthProvider><Users /></AuthProvider> } />
              <Route path="/in/users/create" element={ <AuthProvider><AddUpdateUser /></AuthProvider> } />
              <Route path="/in/users/:id" element={ <AuthProvider><AddUpdateUser /></AuthProvider> } />
              <Route path="/in/auctions" element={ <AuthProvider><Auctions /></AuthProvider> } />
              <Route path="/in/settings" element={ <AuthProvider><Auctions /></AuthProvider> } />
              <Route path="/in/logout" element={ <AuthProvider><Auctions /></AuthProvider> } />
              <Route path="/privacy" element={ <ExtensionPrivacy /> } />
          </Routes>
        }
      </Pane>
    </Router>
  );
}

export default WebRoutes;