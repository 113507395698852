import { setLoading, setVehicles, setView, setError } from "../reducers/vehicles.reducer"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"


export const getVehicles = (currentPage = 1) => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token) {

			dispatch(setLoading(true))

			request.get(`/api/v1/car/get?page=${currentPage}`, {
					headers: {
							Authorization: token
					}
			}).then(res => {
					dispatch(setVehicles(res.data.data.cars))
			}).catch(err => {
					dispatch(setError(err))
			})
	}
}

export const getVehicleById = (id: string | undefined, search = '') => (dispatch: any) => {

	const token 		= LocalStorage.get('token')
	const role 		= LocalStorage.get('role') ? parseInt(LocalStorage.get('role') as string) : 3

	if(token && id) {

			dispatch(setLoading(true))

			const url = (role === 1 || role === 2) ? `/api/v1/car/get/${id}` : role === 3 ? `/api/v1/dealers/cars/${id}` : role === 4 ? `/api/v1/clients/cars/${id}` : ''

    	request.get(url, {
					headers: {
							Authorization: token
					}
			}).then(res => {
				dispatch(setView(res.data.data.car))
			}).catch(err => {
					
			})
	}
}
