import { Alert, FileCard, FileRejectionReason, FileUploader, MimeType,  majorScale, rebaseFiles } from 'evergreen-ui'
import React, { useCallback } from 'react'

function VehicleUpload({ images, setImages, type }) {

    const acceptedMimeTypes = [MimeType.jpeg, MimeType.png] // eslint-disable-line
    const maxFiles = 50
    const maxSizeInBytes = 50 * 1024 ** 2 // 50 MB
    const [fileRejections, setFileRejections] = React.useState([])
    const values = React.useMemo(() => [...images, ...fileRejections.map((fileRejection) => fileRejection.file)], [
        images,
        fileRejections,
    ])
    const handleRemove = useCallback(
        (file) => {
            const updatedFiles = images.filter((existingFile) => existingFile !== file)
            const updatedFileRejections = fileRejections.filter((fileRejection) => fileRejection.file !== file)

            // Call rebaseFiles to ensure accepted + rejected files are in sync (some might have previously been
            // rejected for being over the file count limit, but might be under the limit now!)
            const { accepted, rejected } = rebaseFiles(
                [...updatedFiles, ...updatedFileRejections.map((fileRejection) => fileRejection.file)],
                { acceptedMimeTypes, maxFiles, maxSizeInBytes }
            )
            

            setImages(accepted)
            setFileRejections(rejected)
        },
        [acceptedMimeTypes, images, fileRejections, maxFiles, maxSizeInBytes, setImages]
    )

    const fileCountOverLimit = images.length + fileRejections.length - maxFiles
    const fileCountError = `You can upload up to 5 files. Please remove ${fileCountOverLimit} ${fileCountOverLimit === 1 ? 'file' : 'files'
        }.`

    return (
            <FileUploader
                acceptedMimeTypes={acceptedMimeTypes}
                disabled={images.length + fileRejections.length >= maxFiles}
                maxSizeInBytes={maxSizeInBytes}
                maxFiles={maxFiles}
                onAccepted={(file) => setImages([...images, ...file]) }
                className="box uploadInput flex flex-row items-center justify-center justify-items-center [&>p]:mt-0 gap-4"
                onRejected={setFileRejections}
                height={100}
                renderFile={(file, index) => {
                    const { name, size, type } = file
                    const renderFileCountError = index === 0 && fileCountOverLimit > 0

                    // We're displaying an <Alert /> component to aggregate files rejected for being over the maxFiles limit,
                    // so don't show those errors individually on each <FileCard />
                    const fileRejection = fileRejections.find(
                        (fileRejection) => fileRejection.file === file && fileRejection.reason !== FileRejectionReason.OverFileLimit
                    )
                    const { message } = fileRejection || {}

                    return (
                        <React.Fragment key={`${file.name}-${index}`}>
                            {renderFileCountError && <Alert intent="danger" marginBottom={majorScale(2)} title={fileCountError} />}
                            <FileCard
                                isInvalid={fileRejection != null}
                                name={name}
                                onRemove={() => handleRemove(file)}
                                sizeInBytes={size}
                                type={type}
                                validationMessage={message}
                            />
                        </React.Fragment>
                    )
                }}
                values={values}
            />
    )
}

export default VehicleUpload