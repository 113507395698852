import { Button,Pane, Text } from 'evergreen-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import { IsAuthenticated, User } from '../redux/selectors/authSelectors'
import logo from '../assets/images/lazika-gray.svg'
import './main.css'
import { GoGlobe, GoLocation } from 'react-icons/go'
import { MdPerson4, MdWifiCalling2 } from 'react-icons/md'

const MainContainer = () => {

	const isAuth = useSelector(IsAuthenticated)
	const authUser = useSelector(User)

	const Logo = () => {

		return (
			<Pane className="">
				<img src={logo} alt="logo" className="w-[115px] h-auto brightness-200" />
			</Pane>
		)
	}

	const Header = () => {

		return (
			<Pane className="flex justify-between flex-row p-7 relative">
					<Pane className="flex flex-row justify-center items-center text-white">
						<Logo />
						
					</Pane>
					<Pane className="flex flex-row items-center  text-white mr-3 gap-7">
							<Pane className="flex flex-row gap-8 max-md:hidden">
								<Text className="!text-white text-lg  flex gap-2 items-center"><GoLocation size={20} /> თბილისი, წერეთლის 61</Text>
								<Text className="!text-white text-lg  flex gap-2 items-center"><MdWifiCalling2 size={20} /> +995 500 10 10 30</Text>
							</Pane>
							<Pane className="flex flex-row gap-4">
								<Text className="rounded-md p-2 !text-white"><GoGlobe size={24} /></Text>
							</Pane>
						{isAuth ? <Text className="text-white text-lg flex gap-2 items-center cursor-pointer" onClick={() => window.location.href = '/in'}><MdPerson4 size={20} /> {authUser?.name} {authUser?.surname}</Text> : 
							<Pane>
								<Text className="!text-white cursor-pointer border text-lg !border-white rounded-full p-2 px-4 " onClick={() => window.location.href = '/auth'}>შესვლა</Text>
							</Pane>
						}
					</Pane>
			</Pane>
		)
	}

	const Footer = () => {
		
		return (
			<Pane className="flex items-center justify-between flex-row absolute bottom-0 footer">

				<Text className="!text-white md:hidden flex gap-2 items-center"><GoLocation size={20} /> თბილისი, წერეთლის 61</Text>
				<Text className="!text-white md:hidden flex gap-2 items-center"><MdWifiCalling2 size={20} /> +995 500 10 10 30</Text>

				<Pane className="max-md:hidden">Lazika Auto Import © 2022. All rights reserved.  </Pane>

			</Pane>
		)

	}

	return (
		<Pane>
			<Header />
			<Pane className="main" />

				<Pane className="m-10">
					<Text className="!text-white md:w-1/2 absolute text-wrap overflow-hidden break-words flex flex-col gap-5">
						<div className="text-4xl">ავტომობილების ტრანსპორტირება ამერიკიდან ყველაზე სწრაფად და იაფად</div>
						<div className="">
							თუ გსურს რომ ყველაზე სწრაფად და ყველაზე იაფად ჩამოიყვანო ავტომობილი, მაშინ სწორ ადგილას აღმოჩნდი. ჩვენი გნუდი მზად არის დაგეხმაროთ და ტრანსპორტირება გაუწიოს თქვენთვის სასურველ ნებისმიერ მანქანას. ასევე მოქმედებს სადილერო ფასებიც.
						</div>
						<div><Button onClick={() => window.open("tel:+995 500 10 10 30")}>დაგვიკავშირდი</Button></div>
					</Text>
					
				</Pane>
			
			<Footer />

		</Pane>
	)
}

export default MainContainer