import React, { useEffect, useMemo, useState } from 'react'
import './index.css';
import { Button, Heading, Pane, Text, TextInputField } from 'evergreen-ui';

import slide1 from '../../../assets/images/slide-1.jpg';
import slide2 from '../../../assets/images/slide-2.png';
import slide3 from '../../../assets/images/slide-2.png';
import logo from '../../../assets/images/lazika-gray.svg';
import { Link } from 'react-router-dom';

interface AuthViewProps {
  isAuthenticated: boolean;
  error: string | null;
  onLogin: (username: string, password: string) => void;
}

const AuthView: React.FC<AuthViewProps> = ({ isAuthenticated, error, onLogin }) => {

	const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeClass, setFadeClass] = useState('');
	const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

	const handleLoginButton = () => {
    onLogin(username, password);
  };
	
	const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
	

  const handleProgressLineClick = (index: number) => {
    setFadeClass('fade-in-out');
    setCurrentSlide(index);

    setTimeout(() => {
      setFadeClass('');
    }, 1000); 
  };

	const slides = useMemo(() => [
    { src: slide1, text: "ავტომობილების ტრანსპორტირება ამერიკიდან ყველაზე სწრაფად და იაფად!" },
    { src: slide2, text: "თუ გსურს რომ ყველაზე სწრაფად და ყველაზე იაფად ჩამოიყვანო ავტომობილი, მაშინ სწორ ადგილას აღმოჩნდი" },
    { src: slide3, text: "" },
  ], []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeClass('fade-in-out');
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);

      setTimeout(() => {
        setFadeClass('');
      }, 1000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [slides]);



	return (
		<Pane className="container">
			<Pane className="auth-left">
			<Pane
				className={`h-full w-full bg-cover ${currentSlide === 1 ? 'bg-left' : currentSlide === 2 ? 'bg-right' : 'bg-center'} bg-center bg-no-repeat flex flex-col justify-between ${fadeClass}`}
				style={{ backgroundImage: `url(${slides[currentSlide].src})` }}
			>
					<Pane className="slide-logo flex flex-row gap-5 items-center m-5 ">
						<Link to="/">
								<img src={logo} alt="lazika-logo"  className="w-[115px] h-auto brightness-200"  />
						</Link>
					</Pane>
				<Pane className='slide-bottom'>
					<Pane className="slide-slog">
						{slides[currentSlide].text}
					</Pane>
					<Pane className="slide-progress-lines">
						{slides.map((_, index) => (
                <div
                  key={index}
                  className={`slide-progress-line ${index === currentSlide ? 'active' : ''}`}
									onClick={() => handleProgressLineClick(index)}
                />
              ))}
					</Pane>
				</Pane>
			</Pane>
			</Pane>
			<Pane className="auth-right gap-14 mx-3">
				<Pane className="flex flex-col gap-2">
					<Heading size={700}>გამარჯობა,</Heading>
					<div>კაბინეტში შესასვლელად გთხოვთ გაიაროთ ავტორიზაცია</div>
				</Pane>
				<Pane className="auth-form flex flex-col gap-2">

					<TextInputField
						width={300}
						className="!h-10"
						placeholder="მომხმარებელი"
						value={username}
						onChange={handleUsernameChange}
					/>
					<TextInputField
						width={300}
						className="!h-10"
						placeholder="პაროლი"
						type="password"
						value={password}
						onChange={handlePasswordChange}
					/>

					<Text className="cursor-pointer !text-red-600 self-end mb-10">პაროლის აღდგენა</Text>
					<Button 
						onClick={handleLoginButton}
						className="block !bg-red-600 !text-white !h-10 hover:!bg-red-500"
					>ავტორიზაცია</Button>
				</Pane>
			</Pane>
			<Pane className="absolute bottom-5 right-5 text-[10px] text-gray-400">
				Powered by In6id &copy; 2024
			</Pane>
		</Pane>
	)
}

export default AuthView