import { Pane } from 'evergreen-ui'
import './index.css'

// setActiveTab is a state SET
const FilterTab = ({activeTab, setActiveTab}: {activeTab: number, setActiveTab: any}) => {

	const filterTabItems = ['Copart', 'IAAI']

	const filterTabItemsList = filterTabItems.map((item, index) => {
		return (
			<Pane className="item" data-active={activeTab === index} onClick={() => setActiveTab(index)}>{item}</Pane>
		)
	})


	return (
		<Pane>
			<Pane className="filter-tab">
				<Pane className="label">Auctions:</Pane>
				<Pane className="filter-tab-items">
					{filterTabItemsList}
				</Pane>
			</Pane>
		</Pane>
	)
}

export default FilterTab