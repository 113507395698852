import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/auth.reducer";
import auctionReducer from "../reducers/auction.reducer";
import userReducer from "../reducers/user.reducer";
import myVehiclesReducer from "../reducers/myVehicles.reducer";
import vehiclesReducer from "../reducers/vehicles.reducer";
import allDataReducer from "../reducers/allData.reducer";
import calculatorReducer from "../reducers/calculator.reducer";

const store = configureStore({
    reducer: {
        auth: authReducer,
        auction: auctionReducer,
        user: userReducer,
        vehicles: vehiclesReducer,
        myVehicles: myVehiclesReducer,
        allData: allDataReducer,
        calculator: calculatorReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

export default store;