import { Pagination, Pane, Table } from 'evergreen-ui'
import { useEffect,  useState } from 'react'
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/ui/Loading';
import { useNavigate } from 'react-router-dom';
import { getMyVehicles } from '../../../redux/actions/myVehicles.action';

interface myVehicle {
	id: number
	vin: string
	brand: {name: string}
	model: {name: string}
	year: string
	lot_number: string
	key: number
	archived: boolean
	city: string
	dealer: {
		name: string
		surname: string
	}
	images: any
}


const MyVehicles = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const myVehiclesLoading = useSelector((state: any) => state.myVehicles.loading)
	const myVehiclesList = useSelector((state: any) => state.myVehicles.list.cars)

	const [selectedVehicle, setSelectedVehicle] = useState<myVehicle | null>(null);
	const [filter, setFilter] = useState<string>(""); // eslint-disable-line
	// const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

	// Pagination
	const [currentPage, setCurrentPage] = useState<number>(1);
	const itemsPerPage: number = 10; 
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const filteredVehicles = myVehiclesList && myVehiclesList.filter(
		(vehicle: myVehicle) => 
			vehicle.vin.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.lot_number.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.brand.name.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.model.name.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.dealer.name.toLowerCase().includes(filter.toLowerCase()) ||
			vehicle.dealer.surname.toLowerCase().includes(filter.toLowerCase())
		);

	const vehiclesToDisplay = filteredVehicles && filteredVehicles.slice(startIndex, endIndex);

	useEffect(() => {
		getMyVehicles()(dispatch)
	}, []) // eslint-disable-line

	const getFirstImage = (vehicle: any) : string | undefined => {

		if (vehicle.images?.auction && vehicle.images.auction.length > 0) {
			return vehicle.images.auction[0].url
		}
		if (vehicle.images?.america && vehicle.images.america.length > 0) {
			return vehicle.images.america[0].url
		}
		if (vehicle.images?.loading && vehicle.images.loading.length > 0) {
			return vehicle.images.loading[0].url
		}
		if (vehicle.images?.georgia && vehicle.images.georgia.length > 0) {
			return vehicle.images.georgia[0].url
		}
		
	}

	const GetMyVehiclesList = vehiclesToDisplay && vehiclesToDisplay.map((vehicle: myVehicle) => (

		<Table.Row 
			key={vehicle.id} 
			isSelected={vehicle.id === selectedVehicle?.id}
			onSelect={() => setSelectedVehicle(vehicle)}
			onClick={() => navigate(`/in/vehicles/${vehicle.id}`)}
			isSelectable
			className='[&[aria-current="true"]]:!bg-gray-300/30 [&[aria-current="true"]]:!shadow-inner [&[aria-current="true"]>div.action>button]:inline-block [&[aria-current="true"]>div>span]:!text-black cursor-pointer'
		>
			<Table.Cell className="">
				{getFirstImage(vehicle) ? <img src={getFirstImage(vehicle)} alt="" className="w-24 h-24 object-cover rounded-lg" onError={() => <Pane>ERROR</Pane>} /> : <span className="p-5 bg-neutral-100 text-neutral-400 rounded-lg flex justify-center items-center">No image</span> }
			</Table.Cell>
			<Table.Cell className="">{vehicle.year} {vehicle.brand.name} {vehicle.model.name}</Table.Cell>
			<Table.Cell className="">{vehicle.vin}</Table.Cell>
			<Table.Cell className="">{vehicle.lot_number}</Table.Cell>
			<Table.Cell className="">{vehicle.dealer.name} {vehicle.dealer.surname}</Table.Cell>
		</Table.Row>

	))

	return (
		<Pane className="mt-8 flex flex-col gap-5">
			{/* <Pane className="box bg-white  p-5 rounded-md shadow-sm">
				<Pane className="flex gap-4">
					<Pane className="box">
						<Pane className="label">Tools</Pane>
						<SearchInput placeholder='Search by content' onChange={(e: any) => setFilter(e.target.value)} value={filter} />
					</Pane>
					
				</Pane>
			</Pane> */}

      <Pane className="box bg-white  p-5 rounded-md shadow-sm">
        <Pane className="label">Vehicles</Pane>
				<Table className='!text-xs'>
					<Table.Head className=''>
							<Table.TextHeaderCell>Image</Table.TextHeaderCell>
							<Table.TextHeaderCell>Name</Table.TextHeaderCell>
							<Table.TextHeaderCell>Vincode</Table.TextHeaderCell>
							<Table.TextHeaderCell>Lot Number</Table.TextHeaderCell>
							<Table.TextHeaderCell>Dealer</Table.TextHeaderCell>
					</Table.Head>
					<Table.Body>
						{myVehiclesLoading ? <Table.Row><Table.TextCell><Loading /></Table.TextCell></Table.Row> :
							filteredVehicles && filteredVehicles.length > 0 ? GetMyVehiclesList : <Table.Row><Table.TextCell>No vehicles found</Table.TextCell></Table.Row>
						}
					</Table.Body>
				</Table>
				<Pagination
						className="m-4 self-end [&>*]:gap-1"
						page={currentPage}
						onNextPage={() => setCurrentPage(currentPage + 1)}
						onPreviousPage={() => setCurrentPage(currentPage - 1)}
						totalPages={filteredVehicles && Math.ceil(filteredVehicles.length / itemsPerPage)}
						onPageChange={(page) => setCurrentPage(page)}
				></Pagination>
			</Pane>
		</Pane>
	)
}


export default MyVehicles