import { Alert, Button, Card, Checkbox, Dialog, Heading,  Label,  Pane, SelectMenu, Spinner, Text,TextInputField,  WarningSignIcon } from 'evergreen-ui'
import React, {  useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { FwDatepicker } from '@freshworks/crayons/react';
import { createVehicle, getBrands, getDealers, getModels } from '../../../redux/actions/allData.action'
import VehicleUpload from 'components/features/VehicleUpload'

function VehicleCreate() {

    const bidData = useSelector((state: any) => state.allData.fillFromBid);

    // useEffect(() => {
    //     if (bidData.data) { 
    //         setVin(bidData.data.vin)
    //         setLot(bidData.data.lot_number)
    //         setYear(bidData.data.year)
    //         setHasKey(bidData.data.key === "YES" ? true : false)
    //     }
    // }, []);

    const brands = useSelector((state: any) => state.allData.create.brands)
    const models = useSelector((state: any) => state.allData.create.models)
    const dealers = useSelector((state: any) => state.allData.create.dealers)
    const create = useSelector((state: any) => state.allData.create)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [vin, setVin] = useState('')
    const [lot, setLot] = useState('')
    const [container, setContainer] = useState('')
    const [hasKey, setHasKey] = useState(false)
    const [brand, setBrand] = useState<any>({})
    const [model, setModel] = useState<any>({})
    const [year, setYear] = useState('')
    const [city, setCity] = useState('')
    const [dealer, setDealer] = useState<any>({})

    const [payDate, setPayDate] = useState('')
    const [payPrice, setPayPrice] = useState('')
    const [purchaseDate, setPurchaseDate] = useState('')
    const [purchasePrice, setPurchasePrice] = useState('')
    const [shippingPrice, setShippingPrice] = useState('')

    const [images, setImages] = React.useState<any>([])

    const [imageDialog, setImageDialog] = useState(false)
    const [americaList, setAmericaList] = useState([])
    const [auctionList, setAuctionList] = useState([])
    const [loadingList, setLoadingList] = useState([])
    const [georgiaList, setGeorgiaList] = useState([])

    const [locationType, setLocationType] = useState<any>(null)


    const [filter, setFilter] = React.useState('') // eslint-disable-line

    const convertObjectToArray = (list: any) => {
        const item = Object.keys(list).map((key) => {
            return {
                value: key,
                label: list[key]
            }
        })
        return item
    }

    const OnDealerChange = (item: any) => {
        setDealer(item)
    }

    const useBrands = ()  => {
        !brands.data && !brands.loading && getBrands()(dispatch)
        let data = brands.loading ? [{ label: 'Loading Brands', value: 0 }] : brands.data && convertObjectToArray(brands.data).map((item) => ({ label: item.label, value: parseInt(item.value) }))
        return data
    }

    const useModels = (brandId: any) => {
        brandId && !models.loading && getModels(brandId)(dispatch)
        let data = models.loading ? [{ label: 'Loading Models', value: 0 }] : models.data && convertObjectToArray(models.data).map((item) => ({ label: item.label, value: parseInt(item.value) }))

        return data ?? []
    }

    const OnBrandChange = (item: any) => {
        setBrand(item)
        setModel({})
        useModels(item.value)
    }


    const useDealers = () => {
        !dealers.data && !dealers.loading && getDealers()(dispatch)
        let data = dealers.message ? [{ label: dealers.message, value: 0 }] : dealers.data && dealers.data.map((item: any) => ({ label: `${item.name} ${item.surname}`, value: item.id }))
        console.log(data)
        return data
    }

    const createVehicleHandler = (archived: any) => {
        createVehicle({
            vin: vin,
            container_number: container,
            lot_number: lot,
            year: year,
            city: city,
            key: hasKey ? 1 : 0,
            brand_id: brand.value,
            model_id: model.value,
            dealer_id: dealer.value,
            pay_date: payDate ? payDate : null,
            pay_price: payPrice,
            purchase_date: purchaseDate ? purchaseDate : null,
            purchase_price: purchasePrice,
            shipping_price: shippingPrice,
            image: images,
            archived: archived ? 1 : 0
        })(dispatch)
    }

    const imageModal = useMemo(() => {
        let image: any[] = [];
            let setImage:  React.Dispatch<React.SetStateAction<any[]>> = () => {}; 

            if (locationType === 'america') {
                image = americaList;
                setImage = setAmericaList as  React.Dispatch<React.SetStateAction<any[]>>;
            } else if (locationType === 'auction') {
                image = auctionList;
                setImage = setAuctionList as  React.Dispatch<React.SetStateAction<any[]>>;
            } else if (locationType === 'georgia') {
                image = georgiaList;
                setImage = setGeorgiaList as  React.Dispatch<React.SetStateAction<any[]>>;
            } else if (locationType === 'loading') {
                image = loadingList;
                setImage = setLoadingList as  React.Dispatch<React.SetStateAction<any[]>>;
            } else {
                return null; 
            }

        return (
            <Dialog
                isShown={imageDialog}
                title="Upload Vehicle Images"
                onCloseComplete={() => {
                    setImageDialog(false)
                    setImages({
                        auction: auctionList,
                        america: americaList,
                        loading: loadingList,
                        georgia: georgiaList
                    })
                }
                }
                shouldCloseOnEscapePress={false}
                hasFooter={false}

            >
                <VehicleUpload type={locationType} images={image} setImages={setImage} />
                {image.length === 0 && <Text>No Images, Add some!</Text>}
            </Dialog>
        )
    }, [locationType, imageDialog, americaList, auctionList, georgiaList, loadingList])

    const errorHandler = (errors: any) => {

    const error = Object.keys(errors).map((item, index) => {
            return <li key={index} className="ml-4 mb-2">{errors[item][0]}</li>
        })
        return error
    }

    return (
        <Pane className="mt-8 flex flex-col gap-4">
            <Pane className="flex flex-col gap-4">
            {create.error ? <Alert className="dark:bg-slate-800/75 dark:text-slate-100" intent="danger" title={errorHandler(create.error)} /> : create.data?.car?.id && 
                        <Alert intent={'success'} title={create?.data ? `Vehicle <Link to=${navigate('/in/vehicles/'+create.data?.car?.id, { replace: true })} ${create.data?.car?.id}</Link> Created` : create?.message} />
                    }
                {bidData.data ? 
                    <Card className="card gap-5 flex flex-col">
                        <Text size={500} className="border-b pb-3 dark:text-white">Vehicle Information From Copart</Text>
                            <Pane className="flex flex-row items-center gap-3">
                                <Pane className="flex flex-col gap-2">
                                    <Label><b>LOT:</b> {bidData.data?.lot_number}</Label>
                                    <Label><b>Brand:</b> {bidData.data?.brand}</Label> 
                                    <Label><b>Model:</b> {bidData.data?.model}</Label>
                                </Pane>
                                <Pane className="flex flex-col gap-2">
                                    <Label><b>VIN:</b> {bidData.data?.vin}</Label> 
                                    <Label><b>Year:</b> {bidData.data?.year}</Label>
                                    <Label><b>Key:</b> {bidData.data?.key}</Label>
                                </Pane>
                            </Pane>
                    </Card>
                : null }
                <Card className="card box bg-white p-5 rounded-md gap-10 gap-y-8 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 ss:grid-cols-1">
                    
                    <Pane className="flex flex-row items-center gap-2">
                        <TextInputField value={vin} onChange={(e: any) => setVin(e.target.value)} width="100%" name="vin" label="Vin Code" placeholder="VIN" className="input uppercase" maxLength={17} marginBottom={0} />
                    </Pane>
                    <TextInputField value={container} onChange={(e: any) => setContainer(e.target.value)} name="container_number" label="Container Number" placeholder="Container" marginBottom={0} />
                    <TextInputField value={lot} onChange={(e: any) => setLot(e.target.value)} name="lot_number" label="Lot Number" placeholder="Lot" marginBottom={0} />
                    <Pane className="flex flex-col gap-2">
                        <Label>Brand</Label>
                        <SelectMenu
                            title="Select Brand"
                            onFilterChange={(filter) => setFilter(filter)}
                            options={useBrands()}
                            // selected={brand?.value}
                            onSelect={(item) => OnBrandChange(item)}
                        >
                            <Button>{brand?.label || (brands?.loading ? brands?.message : 'Select Brand')}</Button>
                        </SelectMenu>
                    </Pane>
                    <Pane className="flex flex-col gap-2">
                        <Label>Model</Label>
                        <SelectMenu
                            title="Select Model"
                            onFilterChange={(filter) => setFilter(filter)}
                            options={useModels(false)}
                            selected={model?.value}
                            onSelect={(item) => setModel(item)}
                        >
                            <Button>{model?.label || 'Select Model'}</Button>
                        </SelectMenu>
                    </Pane>
                    <TextInputField value={year} onChange={(e: any) => setYear(e.target.value)} marginBottom={0} name="year" label="Year" placeholder="Year" />
                    <TextInputField value={city} onChange={(e: any) => setCity(e.target.value)} marginBottom={0} name="city" label="City" placeholder="City" />
                    <Checkbox
                        label="Has Key?"
                        name="has_key"
                        checked={hasKey}
                        className='text-slate-50 my-0 dark:text-slate-300 dark:[&>span]:text-slate-300 [&>span]:text-slate-800 items-end mb-2'
                        onChange={e => setHasKey(e.target.checked)}
                    />

                    <Pane className="flex flex-col gap-2">
                        <Label>Dealer</Label>
                        <SelectMenu
                            title="Select Dealer"
                            onFilterChange={(filter) => setFilter(filter)}
                            options={useDealers()}
                            onSelect={(item) => OnDealerChange(item)}
                        >
                            <Button>{dealer?.label || 'Select Dealer'}</Button>
                        </SelectMenu>
                    </Pane>

                    <FwDatepicker fullWidth={true} show-footer="false" clear-input={true} value={payDate} display-format="yyyy-MM-dd" onFwChange={(e: any) => setPayDate(e.target.value)} name="pay_date" label="Pay Date" placeholder="Pay Date" marginBottom={0} />
                    <TextInputField value={payPrice} onChange={(e: any) => setPayPrice(e.target.value)} name="pay_price" label="Pay Price" placeholder="Pay Price" marginBottom={0} />
                    <FwDatepicker fullWidth={true} show-footer="false" clear-input={true} value={purchaseDate} display-format="yyyy-MM-dd" onFwChange={(e: any) => setPurchaseDate(e.target.value)} name="purchase_date" label="Purchase Date" placeholder="Purchase Date" marginBottom={0} />
                    <TextInputField value={purchasePrice} onChange={(e: any) => setPurchasePrice(e.target.value)} name="purchase_price" label="Purchase Price" placeholder="Purchase Price" marginBottom={0} />
                    <TextInputField value={shippingPrice} onChange={(e: any) => setShippingPrice(e.target.value)} name="shipping_price" label="Shipping Price" placeholder="Shipping Price" marginBottom={0} />

                    <Pane className="flex flex-row items-center gap-2 justify-end lg:col-span-4 md:col-span-3 sm:col-span-2 ss:col-span-1">
                        <Pane className="text-xs flex flex-row gap-2 items-center">
                            <WarningSignIcon size={12} /> {create.message ? create.message : ''}
                        </Pane>
                        <Button iconBefore={create.loading ? <Spinner /> : <></>} onClick={() => createVehicleHandler(false)}>Create</Button>
                        <Button onClick={() => createVehicleHandler(true)} className="bg-black hover:bg-[#000] text-slate-200">Archive</Button>
                    </Pane>
                </Card>
                <Pane className="flex flex-col">
                    <Pane className="grid justify-between lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 ss:grid-cols-1 gap-4">
                        {imageModal}
                        <Pane className="flex flex-col gap-2">
                            <Heading className="auto-text">Images</Heading>
                            <Pane className="flex flex-row gap-4">
                                <Button onClick={() => {
                                    setImageDialog(true)
                                    setLocationType('auction')
                                }}>Auction</Button>
                                <Button onClick={() => {
                                    setImageDialog(true)
                                    setLocationType('america')
                                }}>America</Button>
                                <Button onClick={() => {
                                    setImageDialog(true)
                                    setLocationType('loading')
                                }}>Loading</Button>
                                <Button onClick={() => {
                                    setImageDialog(true)
                                    setLocationType('georgia')
                                }}>Georgia</Button>
                            </Pane>
                        </Pane>
                    </Pane>

                </Pane>
            </Pane>
        </Pane>
    )
}

export default VehicleCreate