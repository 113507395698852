import { setUsers, setLoading, setUpdateUser, setError, setCreateUserMessage } from "../reducers/user.reducer"
import { LocalStorage } from "utils/helpers/LocalStorage.helper"
import { request } from "utils/services/axios.config"


export const getUsers = () => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token) {

			dispatch(setLoading(true))

			request.get('/api/v1/users/get', {
					headers: {
							Authorization: token
					}
			}).then(res => {
					dispatch(setUsers(res.data.data.users))
			}).catch(err => {
					dispatch(Error(err))
			})
	}
}

export const getUserById = (id: string | undefined) => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token && id) {

			dispatch(setLoading(true))

			request.get(`/api/v1/users/get/${id}`, {
					headers: {
							Authorization: token
					}
			}).then(res => {
				dispatch(setUpdateUser(res.data.data.user))
			}).catch(err => {
					dispatch(setError(err))
			})
	}
}

export const createUser = (data: any) => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token) {

			request.post('/api/v1/users/register', data, {
					headers: {
							Authorization: token
					}
			}).then(res => {
					dispatch(setCreateUserMessage(res.data.message))
					dispatch(getUsers())
			}).catch(err => {
					dispatch(setError(err.response.data))
			})
	}
}


export const updateUser = (data: any, id: string) => (dispatch: any) => {

	const token = LocalStorage.get('token')

	if(token) {
		
			request.put(`/api/v1/users/update/${id}`, data, {
					headers: {
							Authorization: token
					}
			}).then(res => {
					dispatch(setCreateUserMessage(res.data.message))
					dispatch(getUsers())
			}).catch(err => {
					dispatch(setError(err.response.data))
			})
	}
}

export const getUserGroups = () => (dispatch: any) => {
	
	const token = LocalStorage.get('token')


	if(token) {

		dispatch({
			type: 'user/userGroups',
			payload: {
					type: 'loading',
					data: true
			}
	})

			request.get('/api/v1/dealer-groups/get', {
					headers: {
							Authorization: token
					}
			}).then(res => {
				dispatch({
					type: 'user/userGroups',
					payload: {
							type: 'set',
							data: res.data.data.groups
					}
			})
			}).catch(err => {
					dispatch(Error(err))
			})
	}

}

export const saveEditedGroup = (data: any, onlyPrice: boolean) => (dispatch: any) => {
	
		const token = LocalStorage.get('token')

		// if(onlyPrice) {
		// 	delete data.name
		// }
		console.log(data)

		dispatch({
			type: 'user/userGroups',
			payload: {
					type: 'loading',
					data: true
			}
	})

		if(token) {

			request.put(`/api/v1/dealer-groups/update/${data.id}`, data, {
					headers: {
							Authorization: token
					}
			}).then(res => {
				getUserGroups()(dispatch)
			}).catch((err: any) => {
					console.log(err)
			})
		}
}

export const createGroup = (data: any) => (dispatch: any) => {
	

		const token = LocalStorage.get('token')

		dispatch({
			type: 'user/userGroups',
			payload: {
					type: 'loading',
					data: true
			}
	})

		if(token) {

			request.post('/api/v1/dealer-groups/create', data, {
					headers: {
							Authorization: token
					}
			}).then(res => {
				getUserGroups()(dispatch)
			}).catch((err: any) => {
					console.log(err)
			})
		}

}