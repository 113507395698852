import api from 'utils/api/auth.request';
import { loginFailure, loginSuccess, logoutSuccess } from '../../redux/reducers/auth.reducer';
import { LocalStorage } from '../../utils/helpers/LocalStorage.helper';

export const loginRequest = (username: string, password: string) => async (dispatch: any, navigate: any) => {

  try {
    const response = await api.login(username, password);

    if (response.success) {
      dispatch(loginSuccess({ ...response.data.user, token: response.data.token }));
      navigate('/in');
    } else {
      dispatch(loginFailure(response.data));
    }
  } catch (error: any) {
    dispatch(loginFailure(error));
  }
};

export const authValidation = () => async (dispatch: any) => {

  // Get token from local storage
  const token = LocalStorage.get('token')

  if (token) {

  // dispatch(Loading())

    try {
      const response = await api.validateToken();
      dispatch(loginSuccess(response.data));
    }catch (error: any) {
      dispatch(loginFailure(error));
    }

  }else{
    dispatch(logoutSuccess());
  }
}

export const logoutRequest = () => async (dispatch: any, navigate: any) => {

  await api.logout();
  dispatch(logoutSuccess());
  navigate('/auth');

  
};